export const SearchFacetsList = {
  brands: 'BRANDS',
  amenities: 'AMENITIES',
  propertyType: 'PROPERTY_TYPES',
  activities: 'ACTIVITIES',
  hotelType: 'HOTEL_SERVICE_TYPES',
  events: 'MEETINGS_EVENTS',
  transportation: 'TRANSPORTATION_TYPES',
  leisureRegion: 'LEISURE_REGIONS',
  city: 'CITIES',
  country: 'COUNTRIES',
  state: 'STATES',
  points: 'POINTS',
  distance: 'DISTANCE',
  price: 'PRICE',
};

export const SearchFacetsListCodes = {
  brands: 'brands',
  amenities: 'amenities',
  propertyType: 'property-types',
  activities: 'activities-on-site',
  hotelType: 'hotel-service-types',
  events: 'meetings-and-events',
  transportation: 'transportation-types',
  leisureRegion: 'leisure-region',
  city: 'cities',
  country: 'countries',
  state: 'states',
};

export const SearchFacetsListCodesMap = {
  brands: 'BRANDS',
  amenities: 'AMENITIES',
  'property-types': 'PROPERTY_TYPES',
  'activities-on-site': 'ACTIVITIES',
  'hotel-service-types': 'HOTEL_SERVICE_TYPES',
  'meetings-and-events': 'MEETINGS_EVENTS',
  'transportation-types': 'TRANSPORTATION_TYPES',
  'leisure-region': 'LEISURE_REGIONS',
  cities: 'CITIES',
  countries: 'COUNTRIES',
  states: 'STATES',
};

export const SEARCH_FILTER_MODAL = 'Search Filter Panel Modal';

export const amenityCodeToIconMap: Record<string, string> = {
  'EV Charging': 'ev-charging-station',
  'Whirlpool in Room': 'pool',
  'Whirlpool on Site': 'pool',
  'new-lobby': 'check',
};

export const HDQueryParamsToCheck: string[] = ['activities-on-site', 'property-types', 'amenities'];
export const ALL_INCLUSIVE_CODE = 'all-inclusive';
export const ALL_INCLUSIVE_LABEL = 'All-Inclusive';
export const US_LOCALE = 'en-us';
