import styled from 'styled-components';

import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledSubNavigation = styled.div<{ subNavLength: number; maxItemsPerColumn: number }>`
  padding: ${toRem(20)} ${toRem(16)} ${toRem(12)};
  @media ${baseVariables.mediaQuery.lg} {
    width: auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: ${toRem(40)};
  }

  .sub-navigation {
    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      .list-item {
        border-radius: ${toRem(8)};
        margin: ${toRem(5)} 0;
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(250)};
        }
        @media ${baseVariables.mediaQuery.xl} {
          width: ${toRem(337)};
        }
        &:hover {
          background-color: ${baseVariables.color['neutral10']};
        }

        .list-item-link {
          display: block;
          padding: ${toRem(13)} ${toRem(8)};
        }
      }

      @media ${baseVariables.mediaQuery.lg} {
        column-count: ${({ subNavLength, maxItemsPerColumn }) => {
          return Math.ceil(subNavLength / maxItemsPerColumn);
        }};

        .last-list-item {
          break-after: column;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const StyledCardArticle = styled.div`
  &&& {
    padding: ${toRem(24)} ${toRem(8)};
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    > div {
      box-shadow: none;
      border: none;
      border-radius: 0;
    }
    > div > div {
      box-shadow: none;
      margin: 0;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding: 0 0 0 ${toRem(24)};
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-top: none;
      border-bottom: none;
      width: 100%;
      margin-left: ${toRem(24)};
    }
    .iconblock {
      &-img {
        margin-bottom: ${toRem(16)};
      }
      img {
        max-width: 100vw;
        max-height: fit-content;
        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(313)};
        }
      }
      &-body {
        button {
          margin-top: 0;
        }
        .heading {
          padding: 0;
        }
        .description {
          padding-bottom: 0;
        }
      }
    }
  }
`;
