// Imports for external libraries go here.
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// import { OffersBrandRibbonProps } from './OffersBrandRibbon.types';
import { StyledOffersBrandRibbon, StyledOffersBrandRibbonPreviewMode } from './OffersBrandRibbon.styles';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BrandRibbon } from '@marriott/mi-common-static-components';

export const OffersBrandRibbonWrapperConfig = {
  emptyLabel: 'Offers Brand logos',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/brandribbon`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersBrandRibbon = (props: any) => (
  <EditableComponent config={OffersBrandRibbonWrapperConfig} {...props}>
    {props?.offersData?.isPreview === 'true' ? (
      <StyledOffersBrandRibbonPreviewMode data-testid="brandribbon" data-component-name="o-offers-brandribbon">
        <BrandRibbon {...props} />
      </StyledOffersBrandRibbonPreviewMode>
    ) : (
      <StyledOffersBrandRibbon data-testid="brandribbon" data-component-name="o-offers-brandribbon">
        <BrandRibbon {...props} />
      </StyledOffersBrandRibbon>
    )}
  </EditableComponent>
);
