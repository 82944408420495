/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @nx/enforce-module-boundaries */
import { CardVertical, Types, Text } from '@marriott/mi-ui-library';
import {
  BrandProps,
  OffersSearchResultsCardProps,
  OffersSearchResultsProps,
  availableBrandsType,
} from './OffersSearchResults.types';
import { StyledOffersSearchResults } from './OffersSearchResults.styles';
import {
  PhoenixOffersSearchByArea,
  PhoenixOffersSearchByGeolocation,
  phoenixOffersListSearch,
} from '@marriott/mi-offers-graphql';
import { getDeepLinkParams, getOffersDateFormatted, getTomorrowDate, processAcceptLang } from '../../utils/OfferUtils';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { MEMBER_EXCLUSIVE } from '../../constants/OfferCardConstants';
import {
  OFFERS_SEARCH_FALLBACK_IMAGE,
  OFFER_IMAGE_CACHE_DOMAIN,
  PRODUCT_AND_LOYALTY_OFFER,
  NON_STAY_BASED_OFFERS,
  NON_STAY_BASED_PRODUCT_AND_LOYALTY_OFFER,
  NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER,
  STAY_BASED_ALL,
} from '../../constants/OfferDetailsConstants';
import { INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';
import { SortByFilterComponent } from './sortByFilter';
import { SkeletonOffersSearchResultsLoader } from './SkeletonOffersSearchResultsLoader/index';
import { OffersSearchResultsFilter } from './offersSearchResultsFilter/OffersSearchResultsFilter';
import moment from 'moment';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Pagination } from '@marriott/mi-ui-library';
import { getNthOccurence, shouldUseMockData, useGetBreakpoint } from '../../utils/CommonUtils';
import clsx from 'clsx';
import axios from 'axios';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
export const OffersSearchResultsWrapper: React.FC<OffersSearchResultsProps> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId, sessionData } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  const [ariesOffer, setAriesOffer] = useState<any>({});
  const [ariesSearch, setAriesSearch] = useState<any>({});
  const searchQuery = sessionData?.cacheData?.data?.AriesCommon?.page_url_query_string ?? '';
  const isMobileViewPort = useGetBreakpoint() === 'mobile';
  const acceptLang = processAcceptLang(acceptLanguage ?? '')?.toLowerCase();
  const [offerSearchResult, setofferSearchResult] = useState<OffersSearchResultsCardProps[]>();
  const [cardCount, setCardCount] = useState(0);
  const dataLoaded = useRef<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isDataAvailableNearBy, setIsDataAvailableNearBy] = useState<boolean>(false);
  const [updateCards, setUpdateCards] = useState<boolean>(true);
  const [dataLoaderFlag, setDataLoaderFlag] = useState<boolean>(true);
  const [mockOfferSearchResultUXLMockJson, setMockOfferSearchResultUXLMockJson] = useState<any>({});
  let uxlQuery = phoenixOffersListSearch;

  // brand filter state variables
  const [availableBrands, setAvailableBrands] = useState<availableBrandsType[]>([]); // brands which are cominng as facets from uxl & available for selection
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>(ariesOffer?.['selectedBrands'] ?? []); // brands which are selected for filtering of list
  const [brandFacets, setBrandFacets] = useState<string[]>([]);

  //offer category state variables
  const [availableOfferCategory, setAvailableOfferCategory] = useState<availableBrandsType[]>([]); // Offer Category which are cominng as facets from uxl & available for selection
  const [selectedOfferCategory, setSelectedOfferCategory] = useState<BrandProps[]>(
    ariesOffer?.['selectedOfferCategory'] ?? []
  ); // Offer Category which are selected for filtering of list
  const [offerCategory, setOfferCategory] = useState<string[]>([]);

  //offer type state variables
  const [availableOfferType, setAvailableOfferType] = useState<availableBrandsType[]>([]); // Offer type which are cominng as facets from uxl & available for selection
  const [selectedOfferType, setSelectedOfferType] = useState<BrandProps[]>(ariesOffer?.['selectedOfferType'] ?? []); // Offer type which are selected for filtering of list
  const [offerType, setOfferType] = useState<string[]>([]);

  //STATE state variables
  const [availableFilterState, setAvailableFilterState] = useState<availableBrandsType[]>([]); // State filters which are cominng as facets from uxl & available for selection
  const [selectedFilterState, setSelectedFilterState] = useState<BrandProps[]>(
    ariesOffer?.['selectedFilterState'] ?? []
  ); // State filter data which are selected for filtering of list
  const [filterState, setFilterState] = useState<string[]>([]);

  // CITY state variables
  const [availableFilterCity, setAvailableFilterCity] = useState<availableBrandsType[]>([]); // City which are cominng as facets from uxl & available for selection
  const [selectedFilterCity, setSelectedFilterCity] = useState<BrandProps[]>(ariesOffer?.['selectedFilterCity'] ?? []); // City which are selected for filtering of list
  const [filterCity, setFilterCity] = useState<string[]>([]);

  // COUNTRY state variables
  const [availableFilterCountry, setAvailableFilterCountry] = useState<availableBrandsType[]>([]); // Country which are cominng as facets from uxl & available for selection
  const [selectedFilterCountry, setSelectedFilterCountry] = useState<BrandProps[]>(
    ariesOffer?.['selectedFilterCountry'] ?? []
  ); // Country which are selected for filtering of list
  const [filterCountry, setFilterCountry] = useState<string[]>([]);

  // states for dynamic filters
  const [dynamicBrand, setDynamicBrand] = useState<string[]>([]);
  const [dynamicOfferType, setDynamicOfferType] = useState<string[]>([]);
  const [dynamicOfferCategory, setDynamicOfferCategory] = useState<string[]>([]);
  const [dynamicCity, setDynamicCity] = useState<string[]>([]);
  const [dynamicState, setDynamicState] = useState<string[]>([]);
  const [dynamicCountry, setDynamicCountry] = useState<string[]>([]);

  const urlParams = new URLSearchParams(searchQuery);
  const deepLinkParams = getDeepLinkParams(urlParams, ariesSearch);
  const {
    notEligible = false,
    invalidUser = false,
    invalidDeal = false,
    hasPlaceId = '',
    destinationSearched = null,
    destinationCitySearched = '',
    searchByGeoLoc = false,
    searchBystate = false,
    searchBycountry = false,
    offerIds = [],
    searchByOffersIds = false,
    isEmptyOfferIds = false,
    propertyCodes = [],
    searchByPropertyCodes = false,
    isEmptyPropertyCodes = false,
    searchByStayBasedOfferFlag = false,
    isEmptyStayBasedOfferFlag = false,
  } = deepLinkParams;

  uxlQuery = searchByGeoLoc
    ? PhoenixOffersSearchByGeolocation
    : searchBystate || searchBycountry
    ? PhoenixOffersSearchByArea
    : searchByOffersIds ||
      searchByPropertyCodes ||
      searchByStayBasedOfferFlag ||
      isEmptyOfferIds ||
      isEmptyPropertyCodes ||
      isEmptyStayBasedOfferFlag
    ? phoenixOffersListSearch
    : phoenixOffersListSearch;

  const sortOptions = [
    {
      sortByOptionTitle: model?.offersSortbyExpiringsoon,
      sortByOptionCode: 'BOOKING_END_DATE',
    },
    {
      sortByOptionTitle: model?.offersSortbyNew,
      sortByOptionCode: 'BOOKING_START_DATE',
    },
  ];
  if (searchByGeoLoc) {
    sortOptions.unshift({
      sortByOptionTitle: model?.offersSortbyDistance,
      sortByOptionCode: 'OFFER_DISTANCE',
    });
  }
  const [sortBy, setSortBy] = useState(searchByGeoLoc ? 'OFFER_DISTANCE' : 'BOOKING_END_DATE');

  //pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offsetValue, setOffsetValue] = useState<number>(0);
  const [pageCardCountInfo, setPageCardCountInfo] = useState<string>('');
  const paginationPanelLabel = {
    previous: model?.offersPrevious ?? '',
    next: model?.offersNext ?? '',
  };
  const getDateFormatted = (date: any): string => {
    return date?.format('YYYY/MM/DD') ?? '';
  };

  // Load mock data dynamically for dev and author mode
  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const mockData = await import('./__mock__/OfferSearchResult.uxl');
      return mockData.OfferSearchResultUXLMockJson;
    }
    return {};
  };

  // update dates in session for carrying over the dates to offer details page
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(
      'OfferSearchDates',
      JSON.stringify({
        startDate: getOffersDateFormatted(
          moment(urlParams?.has('fromDate') ? urlParams?.get('fromDate') : moment(new Date()))
        ),
        endDate: getOffersDateFormatted(
          moment(urlParams?.has('toDate') ? urlParams?.get('toDate') : getTomorrowDate(true))
        ),
      })
    );
  }

  useEffect(() => {
    if (globalData['sessionData'] !== undefined) {
      // Once globalData is available, set ariesOffer and ariesSearch
      const offer = globalData?.['AriesOffer'] || globalData?.['sessionData']?.cacheData?.data?.AriesOffer || {};
      const search =
        globalData?.['AriesSearch']?.searchCriteria?.address ||
        globalData?.['sessionData']?.cacheData?.data?.AriesSearch?.searchCriteria?.address ||
        {};
      setAriesSearch(search);
    }
  }, [globalData['sessionData']]);

  const getVariables = () => {
    let obj: any = {};
    const stayStartDate = getDateFormatted(
      moment(urlParams?.has('fromDate') ? urlParams?.get('fromDate') : moment(new Date()))
    );
    const stayEndDate = urlParams?.has('toDate')
      ? getDateFormatted(moment(urlParams?.get('toDate')))
      : getTomorrowDate(true);
    if (
      !searchByOffersIds &&
      !searchByPropertyCodes &&
      !searchByStayBasedOfferFlag &&
      !isEmptyOfferIds &&
      !isEmptyPropertyCodes &&
      !isEmptyStayBasedOfferFlag &&
      (searchByGeoLoc || searchBystate || searchBycountry)
    ) {
      obj = {
        input: {
          options: {
            stayStartDate: stayStartDate,
            stayEndDate: stayEndDate || null,
          },
          facets: {
            terms: [
              {
                type: 'BRANDS',
                dimensions: updateCards ? brandFacets : dynamicBrand,
              },
              {
                type: 'OFFER_TAGS',
                dimensions: updateCards ? offerCategory : dynamicOfferCategory,
              },
              {
                type: 'OFFER_FLAGS',
                dimensions: updateCards ? offerType : dynamicOfferType,
              },
              {
                type: 'STATES',
                dimensions: updateCards ? filterState : dynamicState,
              },
              {
                type: 'CITIES',
                dimensions: updateCards ? filterCity : dynamicCity,
              },
              {
                type: 'COUNTRIES',
                dimensions: updateCards ? filterCountry : dynamicCountry,
              },
            ],
          },
        },
        limit: model?.numberOfResultsPerPage,
        offset: offsetValue,
        participatingPropertiesLimit2: 1,
        sort: {
          field: sortBy,
        },
      };
    } else {
      obj = {
        input: {
          queries: [],
          facets: {
            terms: [
              {
                type: 'BRANDS',
                dimensions: updateCards ? brandFacets : dynamicBrand,
              },
              {
                type: 'OFFER_TAGS',
                dimensions: updateCards ? offerCategory : dynamicOfferCategory,
              },
              {
                type: 'OFFER_FLAGS',
                dimensions: updateCards ? offerType : dynamicOfferType,
              },
              {
                type: 'STATES',
                dimensions: updateCards ? filterState : dynamicState,
              },
              {
                type: 'CITIES',
                dimensions: updateCards ? filterCity : dynamicCity,
              },
              {
                type: 'COUNTRIES',
                dimensions: updateCards ? filterCountry : dynamicCountry,
              },
            ],
          },
        },
        limit: model?.numberOfResultsPerPage,
        offset: offsetValue,
        sort: {
          field: sortBy,
        },
      };
    }

    if (searchByGeoLoc) {
      obj.input.latitude = ariesSearch?.latitude ?? parseFloat(urlParams?.get('destinationAddress.latitude') as string);
      obj.input.longitude =
        ariesSearch?.longitude ?? parseFloat(urlParams?.get('destinationAddress.longitude') as string);
      obj.offersSearchByGeolocationOffset2 = offsetValue;
    } else if (searchBystate && searchBycountry) {
      obj.input.state = ariesSearch?.stateProvince ?? [urlParams?.get('destinationAddress.stateProvince')];
      obj.input.country = ariesSearch?.country ?? [urlParams?.get('destinationAddress.country')];
    } else if (searchBycountry) {
      obj.input.country = ariesSearch?.country ?? [urlParams?.get('destinationAddress.country')];
    }
    if (searchByOffersIds) {
      obj.input.queries = [
        ...obj.input?.queries,
        {
          id: 'offers',
          values: offerIds,
        },
      ];
    }
    if (isEmptyOfferIds || isEmptyPropertyCodes || isEmptyStayBasedOfferFlag) {
      obj.input.queries = [
        ...obj.input?.queries,
        {
          id: 'stay-start-date',
          values: stayStartDate,
        },
        {
          id: 'stay-end-date',
          values: stayEndDate || null,
        },
      ];
      obj.input.offertype = [NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER, STAY_BASED_ALL];
    }

    if (searchByPropertyCodes) {
      obj.input.queries = [
        ...obj.input?.queries,
        {
          id: 'properties',
          values: propertyCodes,
        },
      ];
      obj.input.offertype = [
        NON_STAY_BASED_PRODUCT_AND_LOYALTY_OFFER,
        NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER,
        STAY_BASED_ALL,
      ];
    }
    if (searchByStayBasedOfferFlag) {
      obj.input.queries = [
        ...obj.input?.queries,
        {
          id: 'stay-start-date',
          values: stayStartDate,
        },
        {
          id: 'stay-end-date',
          values: stayEndDate || null,
        },
      ];
      obj.input.offertype = [NON_STAY_BASED_PRODUCT_AND_LOYALTY_OFFER, NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER];
    }
    // dated search
    if (
      !searchByOffersIds &&
      !searchByPropertyCodes &&
      !searchByStayBasedOfferFlag &&
      !isEmptyOfferIds &&
      !isEmptyPropertyCodes &&
      !isEmptyStayBasedOfferFlag &&
      !searchByGeoLoc &&
      !searchBystate &&
      !searchBycountry
    ) {
      obj.input.queries = [
        ...obj.input?.queries,
        {
          id: 'stay-start-date',
          values: stayStartDate,
        },
        {
          id: 'stay-end-date',
          values: stayEndDate || null,
        },
      ];
      obj.input.offertype = [NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER, STAY_BASED_ALL];
    }
    obj.sort.field = sortBy;
    return obj;
  };

  const processofferResultsdata = (offerResultsdata: any) => {
    dataLoaded.current = true;
    if (!updateCards && offerResultsdata) {
      processUxlData(offerResultsdata);
    } else if (offerResultsdata && !isAuthorMode) {
      setHasError(false);
      processUxlData(offerResultsdata);
    } else {
      setDataLoaderFlag(false);
      setHasError(true);
    }
  };
  const [getOffersSearchResultsdata, { data: _offerResultsdata }] = useLazyQuery(uxlQuery, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: processofferResultsdata,
    onError: () => {
      if (updateCards) {
        setDataLoaderFlag(false);
        setHasError(true);
      }
    },
    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLang,
      },
    },
  });

  useEffect(() => {
    if (updateCards) {
      setDataLoaderFlag(true);
      // to load cards in author mode
      if (isAuthorMode) {
        const fetchMockData = async () => {
          const mockData = await GetMockData(isAuthorMode);
          setDataLoaderFlag(false);
          processUxlData(mockData);
          setMockOfferSearchResultUXLMockJson(mockData);
        };
        fetchMockData();
      } else {
        const payload = getVariables();
        getOffersSearchResultsdata({
          variables: payload,
        });
      }
    } else {
      const payload = getVariables();
      getOffersSearchResultsdata({
        variables: payload,
      });
    }
    setOffsetValue(0);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    brandFacets,
    currentPage,
    offerCategory,
    offerType,
    filterState,
    filterCity,
    filterCountry,
    dynamicBrand,
    dynamicOfferCategory,
    dynamicOfferType,
    dynamicCity,
    dynamicCountry,
    dynamicState,
    mockOfferSearchResultUXLMockJson,
  ]);

  const isMemberExclusive = (memberLevel: Array<string>): boolean => {
    if (memberLevel?.find(ele => MEMBER_EXCLUSIVE.includes(ele))) {
      return true;
    }
    return false;
  };

  const checkNearByAvailableCity = (uxlCityData: any, cityInData: string) => {
    const isCityMatch =
      destinationCitySearched !== ''
        ? uxlCityData?.filter((item: any) => item?.description === destinationCitySearched).length > 0
        : false;

    setIsDataAvailableNearBy(
      isCityMatch === false && cityInData !== destinationCitySearched && destinationCitySearched !== ''
    );
  };
  const processUxlData = (uxlData: any) => {
    const defaultImage = OFFERS_SEARCH_FALLBACK_IMAGE;
    const response = isAuthorMode ? mockOfferSearchResultUXLMockJson : uxlData || [];
    const offersResulstdata = isAuthorMode
      ? response?.offersSearch?.edges
      : searchByGeoLoc
      ? response?.offersSearchByGeolocation?.edges
      : searchBystate || searchBycountry
      ? response?.offersSearchByArea?.edges
      : searchByOffersIds ||
        searchByPropertyCodes ||
        searchByStayBasedOfferFlag ||
        isEmptyOfferIds ||
        isEmptyPropertyCodes ||
        isEmptyStayBasedOfferFlag
      ? response?.offersSearch?.edges
      : response?.offersSearch?.edges ?? [];

    const offerFacetsBucket = isAuthorMode
      ? response?.offersSearch?.facets
      : searchByGeoLoc
      ? response?.offersSearchByGeolocation?.facets
      : searchBystate || searchBycountry
      ? response?.offersSearchByArea?.facets
      : searchByOffersIds ||
        searchByPropertyCodes ||
        searchByStayBasedOfferFlag ||
        isEmptyOfferIds ||
        isEmptyPropertyCodes ||
        isEmptyStayBasedOfferFlag
      ? response?.offersSearch?.facets
      : response?.offersSearch?.facets ?? [];

    // get available brands from UXL
    const uxlAvailableBrandData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'BRANDS';
    });
    setAvailableBrands(uxlAvailableBrandData?.[0]?.buckets);

    // get available offer categories from UXL
    const uxlOfferCategoryData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'OFFER_TAGS';
    });
    setAvailableOfferCategory(uxlOfferCategoryData?.[0]?.buckets);

    // get available offer types from UXL
    const uxlOfferTypeData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'OFFER_FLAGS';
    });
    setAvailableOfferType(uxlOfferTypeData?.[0]?.buckets);

    // get available STATE Data from UXL
    const uxlStateData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'STATES';
    });
    setAvailableFilterState(uxlStateData?.[0]?.buckets);

    // get available CITY Data from UXL
    const uxlCityData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'CITIES';
    });
    setAvailableFilterCity(uxlCityData?.[0]?.buckets);
    const cityinData =
      offersResulstdata?.[0]?.node?.participatingProperties?.properties?.[0]?.contactInformation?.address?.city ?? '';
    checkNearByAvailableCity(uxlCityData?.[0]?.buckets, cityinData);

    // get available COUNTRIES Data from UXL
    const uxlCountryData = offerFacetsBucket?.filter((el: { type: { enumCode: string } }) => {
      return el?.type?.enumCode === 'COUNTRIES';
    });
    setAvailableFilterCountry(uxlCountryData?.[0]?.buckets);

    const cardCount = isAuthorMode
      ? response?.offersSearch?.total
      : searchByGeoLoc
      ? response?.offersSearchByGeolocation?.total
      : searchBystate || searchBycountry
      ? response?.offersSearchByArea?.total
      : searchByOffersIds ||
        searchByPropertyCodes ||
        searchByStayBasedOfferFlag ||
        isEmptyOfferIds ||
        isEmptyPropertyCodes ||
        isEmptyStayBasedOfferFlag
      ? response?.offersSearch?.total
      : response?.offersSearch?.total ?? 0;
    if (cardCount === 0 || ((isEmptyOfferIds || isEmptyPropertyCodes || isEmptyStayBasedOfferFlag) && cardCount > 0)) {
      setHasError(true);
    }
    if (updateCards) {
      setPageCardCountInfo(getPageCardCountInfo(cardCount));
    }
    setCardCount(cardCount);
    const offerData: OffersSearchResultsCardProps[] = offersResulstdata?.map((item: any, index: number) => {
      const offerId = item?.node?.id?.toLowerCase();
      const header = item?.node?.title;
      const description = item?.node?.descriptionTeaser;
      const offerUrl = item?.node?.url;
      const numProperties = item?.node?.numProperties ?? 0;
      const url = offerUrl?.substring(getNthOccurence(offerUrl, '/', 3), offerUrl?.length);
      const seoNickname = item?.node?.seoNickname;
      const urlTitle = item?.node?.urlTitle;
      const propertyDetails = item?.node?.participatingProperties?.properties?.[0];
      const marshaCode = propertyDetails?.id?.toLowerCase();
      const spoUrl = `/offers/${urlTitle}-${offerId}/${marshaCode}-${seoNickname}`;
      const offerCta = numProperties === 1 ? spoUrl : url;
      const parentOfferType = item?.node?.parentOfferType;
      const offerType = item?.node?.offerType;
      const callToActionLabel = item?.node?.callToActionLabel;
      const callToActionLink = item?.node?.callToActionLink;
      const ctaLinkText = parentOfferType === NON_STAY_BASED_OFFERS ? callToActionLabel : model?.offersDetails;
      const ctaLink = offerType === PRODUCT_AND_LOYALTY_OFFER ? callToActionLink : offerCta;
      const imageNodePrimary = item?.node?.media?.primaryImage?.imageUrls?.wideHorizontal;
      const images = item?.node?.photos?.images;
      const wideImage = images?.filter((img: { aspectRatio: string; url: any }) => img.aspectRatio === 'Wide');
      const photoImage = wideImage?.length > 0 ? OFFER_IMAGE_CACHE_DOMAIN + wideImage?.[0]?.url : defaultImage;
      const imageMapping =
        imageNodePrimary && imageNodePrimary !== null && imageNodePrimary !== ''
          ? OFFER_IMAGE_CACHE_DOMAIN + imageNodePrimary //media image
          : photoImage; // photo image
      const image = cardCount && cardCount > 0 ? imageMapping : defaultImage;
      const cardLocDetails = model?.trackingProperties?.location
        ? `${model.trackingProperties.location}-card-${index}`
        : `card-${index}`;
      const merchandCat = model?.trackingProperties?.merchandisingCategory
        ? model.trackingProperties.merchandisingCategory
        : 'nonCobrand';

      const propertyName = propertyDetails?.basicInformation?.name;

      const locationVal = numProperties > 0 ? (numProperties > 1 ? model?.offersMultipleLocation : propertyName) : '';
      const showCityIcon = locationVal !== '' && locationVal !== undefined && numProperties > 0;
      const memberLevel = item?.node?.memberLevel;
      const MemberExclusive = isMemberExclusive(memberLevel) ?? false;
      const badgeText = MemberExclusive ? model?.offersMemberexclusive : '';

      return {
        cardId: offerId,
        cardProps: {
          header: header,
          headerTag: 'h2',
          description: description,
          cardVerticalVariations: 'Tall16:9',
          dynamicMedia: {
            altText: header,
            assetPath: image,
            dynamic: true,
            renditions: [
              {
                renditionPath: `${image}?downsize=364px:*`,
                mediaValue: '768px',
                width: 0,
                dynamic: true,
                damPath:
                  '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                mediaQuery: 'min-width',
                height: 0,
              },
              {
                renditionPath: `${image}?downsize=344px:*`,
                mediaValue: '576px',
                width: 0,
                dynamic: true,
                damPath:
                  '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                mediaQuery: 'min-width',
                height: 0,
              },
              {
                renditionPath: `${image}?downsize=575px:*`,
                mediaValue: '576px',
                width: 0,
                dynamic: true,
                damPath:
                  '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
                mediaQuery: 'max-width',
                height: 0,
              },
            ],
            damPath:
              '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
          },
          ctaType: 'primaryButton',
          ':type': 'mi-aem-common-spa/components/content/cardvertical',
          badgeIcon: 'icon-lock',
          badgeText: badgeText,
          imgAltText: header,
          fileReferenceImage:
            '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-gettyimages-1041814538-24502.jpg',
          ctaLinkText: ctaLinkText,
          ctaLink: ctaLink,
          trackingProperties: {
            atCCeVar48: model?.trackingProperties?.atCCeVar48 ?? null,
            trackingDescription: header,
            clickTrack: model?.trackingProperties?.clickTrack,
            impressionTrack: model?.trackingProperties?.impressionTrack,
            location: model?.trackingProperties?.location,
            trackingContentPosition: cardLocDetails,
            merchandisingCategory: merchandCat,
            impressionCount: model?.trackingProperties?.impressionCount,
            trackingTag: model?.trackingProperties?.trackingTag,
            trackingOfferType: model?.trackingProperties?.trackingOfferType ?? null,
            cardLocation: model?.trackingProperties?.cardLocation,
          },
          iconFontValue: showCityIcon ? 'icon-city' : '',
          iconText: locationVal,
          styleclass: model?.cardTheme,
          openInNewTab: false,
        },
      };
    });
    setDataLoaderFlag(false);
    if (updateCards) {
      setofferSearchResult(offerData);
    }
  };
  const handleSortBy = (event: any, sortOption: string): void => {
    setDataLoaderFlag(true);
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setSortBy(sortOption);
      setCurrentPage(1);
    }
  };
  //update session with selected filters
  const updateSessionObj = async (ariesOffer: any = {}) => {
    const sessionToken = sessionData?.sessionToken;
    try {
      const url = '/mi/phoenix-gateway/v1/updateSession';
      const headers = {
        Cookie: `sessionID=${sessionToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      };
      const payload = {
        AriesOffer: ariesOffer,
        remove: [],
      };
      await axios
        .post(url, payload, {
          headers: headers,
        })
        .then((res: any) => {
          return res?.status;
        });
    } catch (error) {
      console.log(`[OSERP] filters update session API Failed:, ${error}`); // eslint-disable-line no-console
    }
  };
  const addFilterStateToSession = (
    selectedBrandsList: any,
    selectedOfferCategoryList: any,
    selectedOfferTypeList: any,
    selectedFilterStateList: any,
    selectedFilterCityList: any,
    selectedFilterCountryList: any
  ) => {
    let ariesOffer: any = sessionData?.cacheData?.data?.AriesOffer;
    ariesOffer = {
      ...ariesOffer,
      selectedBrands: selectedBrandsList,
      selectedOfferCategory: selectedOfferCategoryList,
      selectedOfferType: selectedOfferTypeList,
      selectedFilterState: selectedFilterStateList,
      selectedFilterCity: selectedFilterCityList,
      selectedFilterCountry: selectedFilterCountryList,
    };
    let updatedSessionData: any = sessionData;
    updatedSessionData = {
      ...updatedSessionData,
      cacheData: {
        ...updatedSessionData?.cacheData,
        data: {
          ...updatedSessionData?.cacheData?.data,
          AriesOffer: ariesOffer,
        },
      },
    };
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('OffersfilterData', JSON.stringify({ ...ariesOffer }));
    }
    updateGlobalData('sessionData', updatedSessionData);
    updateSessionObj(ariesOffer);
  };

  //on click of apply filter button
  const handleApplyFilter = (
    selectedBrandsList: any,
    selectedOfferCategoryList: any,
    selectedOfferTypeList: any,
    selectedFilterStateList: any,
    selectedFilterCityList: any,
    selectedFilterCountryList: any
  ) => {
    setUpdateCards(true);
    addFilterStateToSession(
      selectedBrandsList,
      selectedOfferCategoryList,
      selectedOfferTypeList,
      selectedFilterStateList,
      selectedFilterCityList,
      selectedFilterCountryList
    );
    setDataLoaderFlag(true);
    setSelectedBrands(selectedBrandsList);
    const brandFacetList = selectedBrandsList?.map((item: any) => item?.code);
    setBrandFacets(brandFacetList);

    setSelectedOfferCategory(selectedOfferCategoryList);
    const offerCategoryFacetList = selectedOfferCategoryList?.map((item: any) => item?.code);
    setOfferCategory(offerCategoryFacetList);

    setSelectedOfferType(selectedOfferTypeList);
    const offerTypeFacetList = selectedOfferTypeList?.map((item: any) => item?.code);
    setOfferType(offerTypeFacetList);

    setSelectedFilterState(selectedFilterStateList);
    const stateFacetList = selectedFilterStateList?.map((item: any) => item?.code);
    setFilterState(stateFacetList);

    setSelectedFilterCity(selectedFilterCityList);
    const cityFacetList = selectedFilterCityList?.map((item: any) => item?.code);
    setFilterCity(cityFacetList);

    setSelectedFilterCountry(selectedFilterCountryList);
    const countryFacetList = selectedFilterCountryList?.map((item: any) => item?.code);
    setFilterCountry(countryFacetList);
    setCurrentPage(1);
  };

  // update filter state onload using session filter data
  useEffect(() => {
    const ariesOffer = JSON.parse(window.sessionStorage.getItem('OffersfilterData') ?? '{}');
    setAriesOffer(ariesOffer);
    handleApplyFilter(
      ariesOffer?.['selectedBrands'] ?? [],
      ariesOffer?.['selectedOfferCategory'] ?? [],
      ariesOffer?.['selectedOfferType'] ?? [],
      ariesOffer?.['selectedFilterState'] ?? [],
      ariesOffer?.['selectedFilterCity'] ?? [],
      ariesOffer?.['selectedFilterCountry'] ?? []
    );
  }, []);

  const handleClearAllFilters = () => {
    setUpdateCards(true);
    setSelectedBrands([]);
    setSelectedOfferCategory([]);
    setSelectedOfferType([]);
    setSelectedFilterState([]);
    setSelectedFilterCity([]);
    setSelectedFilterCountry([]);
    setCurrentPage(1);
  };

  const handleDynamicBrands = (brands: any) => {
    setUpdateCards(false);
    setSelectedBrands(brands);
    const brandFacetList = brands?.map((item: any) => item?.code);
    setDynamicBrand(brandFacetList);
  };
  const handleDynamicOfferType = (offertype: any) => {
    setUpdateCards(false);
    setSelectedOfferType(offertype);
    const offerTypeFacetList = offertype?.map((item: any) => item?.code);
    setDynamicOfferType(offerTypeFacetList);
  };
  const handleDynamicOfferCategory = (category: any) => {
    setUpdateCards(false);
    setSelectedOfferCategory(category);
    const offerCategoryFacetList = category?.map((item: any) => item?.code);
    setDynamicOfferCategory(offerCategoryFacetList);
  };
  const handleDynamicState = (state: any) => {
    setUpdateCards(false);
    setSelectedFilterState(state);
    const stateFacetList = state?.map((item: any) => item?.code);
    setDynamicState(stateFacetList);
  };
  const handleDynamicCity = (city: any) => {
    setUpdateCards(false);
    setSelectedFilterCity(city);
    const cityFacetList = city?.map((item: any) => item?.code);
    setDynamicCity(cityFacetList);
  };
  const handleDynamicCountry = (country: any) => {
    setUpdateCards(false);
    setSelectedFilterCountry(country);
    const countryFacetList = country?.map((item: any) => item?.code);
    setDynamicCountry(countryFacetList);
  };

  // Pagination
  const onPageItemClick = (page: number): void => {
    setDataLoaderFlag(true);
    setCurrentPage(page);
    const offset = (page - 1) * model?.numberOfResultsPerPage;
    setOffsetValue(offset);
    window.scrollTo(0, 0);
  };
  const getPageCardCountInfo = (cardsTotalCount: number): string => {
    const total = Math.ceil(cardsTotalCount / model?.numberOfResultsPerPage);
    const start = total === 1 || currentPage === 1 ? 1 : (currentPage - 1) * model?.numberOfResultsPerPage + 1;
    const end =
      total === currentPage || currentPage === cardsTotalCount
        ? cardsTotalCount
        : currentPage * model?.numberOfResultsPerPage;
    const offersLabel = model?.offersLabel;

    const countText =
      cardsTotalCount === 0
        ? ''
        : `${model?.offersShowing} ${start}-${end} ${model?.offersOf} ${cardsTotalCount} ${offersLabel}`;

    return countText;
  };

  const hasAppliedFilter =
    brandFacets?.length > 0 ||
    offerCategory?.length > 0 ||
    offerType?.length > 0 ||
    filterState?.length > 0 ||
    filterCity?.length > 0 ||
    filterCountry?.length > 0;
  const showFilters = cardCount > 0 || (cardCount === 0 && hasAppliedFilter);
  const isValidSearch = searchBystate || searchBycountry || searchByGeoLoc;
  const showOppsError = destinationSearched !== '' && hasPlaceId === '' && !isValidSearch ? true : false;

  return (
    <div>
      <StyledOffersSearchResults>
        {dataLoaderFlag ? (
          <div>
            <SkeletonOffersSearchResultsLoader />
          </div>
        ) : (
          <>
            {/* Show error msg when destination searched is not valid */}
            {showOppsError && !hasError && !isDataAvailableNearBy && !notEligible && !invalidUser && !invalidDeal && (
              <div className="mb-3">
                <InlineMessages type={InlineMessagesType.Error} title={model?.offersOppsErrorMessage} severity="1" />
              </div>
            )}
            {/* error msg for not eligible user */}
            {notEligible && (
              <div className="mb-3">
                <InlineMessages type={InlineMessagesType.Error} title={model?.offersErrorMessageLabel} severity="1" />
              </div>
            )}
            {/* error msg for invalid user */}
            {invalidUser && (
              <div className="mb-3">
                <InlineMessages type={InlineMessagesType.Error} title={model?.offersInvalidUserMessage} severity="1" />
              </div>
            )}
            {/* error msg for Invalid/Expired offer */}
            {invalidDeal && (
              <div className="mb-3">
                <InlineMessages type={InlineMessagesType.Error} title={model?.offersInvalidErrorMessage} severity="1" />
              </div>
            )}
            {/* Show error msg when UXL response is empty or when UXL call is made with empty OfferIds / PropertyCodes / StayBasedOfferFlag  */}
            {hasError && !notEligible && !invalidUser && !invalidDeal && (
              <div className="mb-3">
                <InlineMessages type={InlineMessagesType.Error} title={model?.errorMessage} severity="1" />
              </div>
            )}
            {/* Show info msg when offers in destination are not found but available in near by destinations  */}
            {!hasError && isDataAvailableNearBy && !notEligible && !invalidUser && !invalidDeal && (
              <div className="mb-3">
                <InlineMessages
                  type={InlineMessagesType.Info}
                  title={model?.offersOserpInfoMessage.replace('{x}', destinationCitySearched)}
                  severity="1"
                />
              </div>
            )}

            {/* to show filters when after applying filters, search does not return data and user has to modify the filters */}
            {showFilters && (
              <div className="offer-search-filter mb-3">
                <OffersSearchResultsFilter
                  ariesOffer={ariesOffer}
                  model={model}
                  handleApplyFilter={handleApplyFilter}
                  handleClearAllFilters={handleClearAllFilters}
                  selectedBrands={selectedBrands}
                  selectedOfferType={selectedOfferType}
                  selectedOfferCategory={selectedOfferCategory}
                  selectedFilterState={selectedFilterState}
                  selectedFilterCity={selectedFilterCity}
                  selectedFilterCountry={selectedFilterCountry}
                  availableOfferCategory={availableOfferCategory}
                  availableBrands={availableBrands}
                  availableOfferType={availableOfferType}
                  availableFilterCity={availableFilterCity}
                  availableFilterState={availableFilterState}
                  availableFilterCountry={availableFilterCountry}
                  brandFacets={brandFacets}
                  offerCategory={offerCategory}
                  offerType={offerType}
                  filterState={filterState}
                  filterCity={filterCity}
                  filterCountry={filterCountry}
                  handleDynamicBrands={handleDynamicBrands}
                  handleDynamicOfferType={handleDynamicOfferType}
                  handleDynamicOfferCategory={handleDynamicOfferCategory}
                  handleDynamicState={handleDynamicState}
                  handleDynamicCity={handleDynamicCity}
                  handleDynamicCountry={handleDynamicCountry}
                />
              </div>
            )}
            {cardCount > 0 && (
              <>
                <div className="sort-by-wrapper mb-1">
                  <div className="page-card-count">
                    <Text
                      element={Types.tags.paragraph}
                      copyText={pageCardCountInfo}
                      fontSize={Types.size.large}
                      customClass={clsx(isMobileViewPort ? 't-font-s' : 't-subtitle-l', 'pb-1', 'mb-0')}
                    />
                  </div>

                  <SortByFilterComponent
                    handleSortBy={handleSortBy}
                    sortOptions={sortOptions}
                    currSortByOption={sortBy}
                    offersSortby={model?.offersSortby}
                  />
                </div>

                <div className="container-wrapper mt-3">
                  {offerSearchResult?.map(card => (
                    <div className="col-xs-12 col-md-6 col-lg-4 mb-4">
                      <CardVertical
                        {...card?.cardProps}
                        custom_click_track_value={`${model?.id}|${card?.cardId}-${card?.cardProps?.header}|${INTERNAL_CTA_TYPE}`}
                      />
                    </div>
                  ))}
                </div>

                {cardCount && cardCount > model?.numberOfResultsPerPage && (
                  <div className="pagination-container mt-5" data-testid="pagination-panel">
                    <Pagination
                      currentPage={currentPage}
                      totalCount={cardCount}
                      pageSize={model?.numberOfResultsPerPage}
                      onPageChange={(page: number): void => onPageItemClick(page)}
                      labels={paginationPanelLabel}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </StyledOffersSearchResults>
    </div>
  );
};
export default OffersSearchResultsWrapper;
