import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { StyledFilterComponentDiv } from '../../molecules/filtersComponent/index.styles';
import { StyledPaginationSortByComponentDiv } from '../../molecules/paginationSortBy/index.styles';
import { StyledSearchSummaryContainerDiv } from '../../molecules/searchResultsSummary/index.style';
import { StyledSortByFilterContainerDiv } from '../../molecules/sortByFilter/index.styles';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledSearchResultPropertyContainer = styled.div`
  ${rtl``}/* overflow-x:hidden; */
`;

export const StyledSearchResultMapContainer = styled.div`
  ${rtl`
  width: 100%;
  //z-index: 0;
  `}
`;

export const StyledSearchResultContainerDiv = styled.div`
  ${rtl`
  &.not-scrolled {
    position: sticky;
    @media only ${theme.mediaQuery.allMobiles} {
      top: ${toRem(157)};
    }
  }
  
  &.list-view.no-map-button {
    .map-button-container {
      display: none;
    }
  }

  .unsuccess-full-journey {
    margin-top: ${toRem(16)};
  }

  @media ${theme.mediaQuery.desktop} {
    .unsuccess-full-journey {
      margin-bottom: ${toRem(8)};
    }
  }

  .text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .filters-container-hidden {
    display: none;
  }
  &.map-view {
    min-height: 80vh;
    margin-top: ${toRem(0)};
    position: relative;
    .filters-container.filters-map-view-container {
      padding: ${toRem(16)} 0;
      margin-top: 0px;
      margin-bottom: 0;
      position: relative;
      z-index: 2;
      overflow: hidden;
      box-shadow: ${theme.color['filter-box-shadow']};
      ${StyledFilterComponentDiv} {
        @media only ${baseVariables.mediaQuery.xl} {
          padding: 0 ${toRem(38)} !important;
        }
        margin-bottom: 0;
        .row-1 {
          padding-bottom: 0;
        }
      }
    }
    .search-form-container {
      .drawer-icon-container {
        position: absolute;
        top: ${toRem(15.5)};
        font-size: ${toRem(20)};
        background-color: ${theme.color['white']};
        &.active-drawer {
          left: ${toRem(378)};
        }
        &.hide-drawer {
          z-index: 1 !important;
        }
        &:focus {
          outline: ${toRem(5)} auto -webkit-focus-ring-color;
        }
        width: ${toRem(38)};
        height: ${toRem(41)};
        left: 0;
        z-index: 4;
        padding: ${toRem(14.5)} ${toRem(14.5)} ${toRem(14.5)} ${toRem(9.5)};
        align-items: center;
        justify-content: center;
        border-top-right-radius: ${toRem(30)};
        border-bottom-right-radius: ${toRem(30)};
        display: none;
        @media only ${theme.mediaQuery.minDesktop} {
          display: flex;
        }
        .icon-arrow-left,
        .icon-arrow-right {
          position: absolute;
          z-index: 1;
          &.first {
            right: ${toRem(15)};
          }
          &.second {
            right: ${toRem(10)};
          }
        }
      }
      max-width: 100%;
      margin: 0;
      position: relative;
      .search-map-view-result-container.search-results-container {
        display:contents;
        //max-width: ${toRem(391)};
        margin-left: 0;
        overflow-y: auto;
        @media only ${theme.mediaQuery.tabletMax} {
          padding-left: 0;
        }
        padding-left: ${toRem(24)};
        box-shadow: 0 ${toRem(21)} ${toRem(20)} 0 rgb(0 0 0 / 30%);
        ::-webkit-scrollbar {
          width: ${toRem(5)};
        }
        ::-webkit-scrollbar-track {
          background: ${theme.color['base-20']};
          border-radius: ${toRem(2)};
          margin: ${toRem(12)} 0;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${theme.color['light-gray-9']};
          border-radius: ${toRem(2)};
        }
        ::-webkit-scrollbar-thumb:hover {
          background: ${theme.color['dark-gray-10']};
        }

        // @media only ${theme.mediaQuery.minDesktop} {
        //   display: inline-block;
        // }
        width: 0;
        opacity: 0;
        left: -100%;
        position: absolute;
        z-index: -2;
        &.active-drawer {
          display:flex;
          left: 0;
          z-index: 1;
          opacity: 1;
          width: 100%;
          overflow:hidden;
          height: calc(100vh - 185px);
        }
        &.map-popup-position{
          z-index:2;
          display:flex;
        }

        ${StyledPaginationSortByComponentDiv} {
          padding-top: ${toRem(14)};
          padding-left:0;
          ${StyledSearchSummaryContainerDiv} {
            font-size: ${toRem(14)};
          }
          ${StyledSortByFilterContainerDiv} {
            .sort-by-section {
              .sort-by-label,
              .sort-by-options {
                font-size: ${toRem(13)};
                .current-sort-option {
                  padding-right: ${toRem(5)};
                }
              }
            }
          }
        }
        .property-card-container {
          min-width: auto;
          height: auto;
          flex-wrap: wrap;
          .image-gallery-container {
            width: 100%;
          }
        }
        .pagination-container{
          margin-left: ${toRem(-25)};
          margin-right: ${toRem(4)};
        }
      }
    }
  }
  .search-form-container {
    max-width: ${toRem(1440)};
    margin: auto;
    display: flex;
    flex-direction: row;
    .filters-container {
      width: auto;
      margin: 0;
    }
    .search-results-container {
      width: auto;
      margin: auto;
      min-height: 80vh;
    }
    .hide-property-list{
      display: none;
    }
  }
  .search-results-container{
    height:100%;
  }
  @media only ${theme.mediaQuery.allTablets} {
    .map-button-container {
      width: fit-content;
      text-align: center;
      max-width: 100%;
      padding-bottom:1% !important;
    }
    .search-map-view-result-container.search-results-container{

      &.active-drawer {
        display:contents !important;
        padding-right: 0;
        padding-left: 0;
        left: 0;
        z-index: 3;
        opacity: 1;
        width: 100%;
        overflow:hidden;
      }
    }
    .search-form-container {
      flex-direction: column;
      .search-results-container {
        width: 100%;
      }
      .filters-container {
        margin: 0;
        width: 100%;
        max-width: inherit;
      }
    }
  }
  @media only ${theme.mediaQuery.allMobiles} {
    .m-message-inline.warning {
      flex: 0 0 96%;
    }
    .map-button-container {
      width: fit-content;
      text-align: center;
      max-width: 100%;
    }
    .search-map-view-result-container.search-results-container{

      &.active-drawer {
        display:contents !important;
        padding-right: 0;
        padding-left: 0;
        left: 0;
        z-index: 3;
        opacity: 1;
        width: 100%;
        overflow-y:hidden;
      }
    }
    .search-form-container {
      flex-direction: column;
      .search-results-container {
        width: 100%;
      }
      .filters-container {
        margin: 0;
        width: 100%;
        max-width: inherit;
      }
    }
  }
  .map-button-container {

    width: fit-content;
    text-align: center;
    max-width: 100%;

    &.fixed-button{
      position: sticky;
      z-index: 2;
      bottom:env(safe-area-inset-bottom);
      left: 0;
      right: 0;
      padding-bottom:${toRem(24)};
    }
    .map-button {
      min-height: ${toRem(40)};
      min-width: ${toRem(110)};
      border-radius: ${toRem(20)};
      font-size: ${toRem(16)};
      font-weight: ${theme.fonts['fontWeightMedium']};
      font-family: ${theme.fontFamily.swiss};
      box-shadow: 0 ${toRem(3)} ${toRem(5)} ${toRem(3)} rgba(0,0,0,0.4);

      .icon-map {
        margin-right: ${toRem(5)};
        color: ${theme.color['light-orange-2']};
        &:before {
          color: ${theme.color['black']};
        }
      }
      &:focus {
        border: ${toRem(1)} solid ${theme.color['black']};
      }
    }
  }`}
`;

export const StyledFilterContainerDiv = styled.div`
  ${rtl`
  &.filters-map-view-container {
    .container {
      @media ${theme.mediaQuery.allTablets} {
        max-width: fit-content;
        margin-left: ${toRem(23)};
        margin-right: auto;
      }
    }
  }
  `}
`;

export const StyledCheckboxItemsContainer = styled.div`
  ${rtl`
    display: flex;
    flex-wrap: wrap;
    .item-container{
      margin-top:${toRem(-24)};
    }
    .amenity-description{
      margin-top:${toRem(-24)};
    }
    .hotel-type-description{
      margin-top:${toRem(-24)};
    }
    .cities-filters{
      margin-top:${toRem(-20)};
    }
    .state-filters{
      margin-top:${toRem(-20)};
    }
  `}
`;

export const StyledCheckboxItemDiv = styled.div`
  ${rtl`
  width: 50%;
  padding: ${toRem(8)} 0;
  input[type='checkbox'] {
    top:${toRem(25)};
    left:${toRem(2)};
    position:relative;
  }
  
  input[type='checkbox'] + label {
    &.checkbox-label {
      margin-bottom: 0;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: ${toRem(26)};
      &:before {
        top: ${toRem(2)};
        left: 0;
      }
      &:after {
        left: ${toRem(6)};
        top: ${toRem(3)};
      }
      .item-icon {
        padding-right: 0;
        font-size: ${toRem(16)};
      }
      .item-name {
        padding-left: ${toRem(5)};
      }
    }
  }`}
`;

export const StyledAlertNotificationDiv = styled.div`
  ${rtl`
    background: ${theme.color.background};
  }`}
`;
