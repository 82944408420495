export type Product = 'rfp' | 'rfp_intl' | 'reslink' | 'quick_group' | 'quick_group_inquiry';

export type Operation = 'create' | 'modify' | 'inquiry' | 'submission';

/*This function will provide information such as the product, operation, locale, 
request ID, and additional prefixes like 'siebel' or 'henry' for the x-request-id. 
This information will be included in the request header, allowing the backend
 to use it for creating the Dynatrace dashboard */
export function createCustomXRequestId(
  product: Product,
  operation: Operation,
  requestId: string,
  locale: string,
  extraPrefix = ''
) {
  const prodIdentifier = extraPrefix !== '' ? `${product}_${extraPrefix}` : product;
  return `${prodIdentifier}_${operation}__${locale}__${requestId}`;
}
