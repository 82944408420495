/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from 'react';
import { StyledMeetingsAndEventsForm } from './MeetingsAndEventsSearchForm.styles';
import { MeetingsAndEventsSearchProps } from './MeetingsAndEventsSearchForm.types';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StaticDataContextProvider } from '../../modules/context';
import { useCheckBreakpoint, Text, Types, Messages } from '@marriott/mi-ui-library';
import { SearchField, DatePicker, HotelsAndEvents } from '../../molecules';
import { StyledWrapperDiv } from './MeetingsAndEventsSearchForm.styles';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { getSessionData } from '@marriott/mi-headless-utils';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
import { logger } from '../../utils/src/logger';
import {
  ADV_SEARCH_FORM_CURR_DATE_ROOM_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_MORE_THAN_TEN_ROOMS_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_TEN_ROOMS_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_TEN_TO_TWENTY_FIVE_ROOMS_COUNT_ERROR_CODE,
} from '../../constants/lib/constants';
import { returnApplicableAlert } from '../../utils/src/utils';

if (global && !global.loggerInstance) {
  global.loggerInstance = logger({ requestID: '', sessionID: '' }) as CallableFunction;
}

const MeetingsSearchForm: FC<MeetingsAndEventsSearchProps> = props => {
  const { model } = props;
  const alertsListArray = props?.model?.alertsListArray;
  const callGetSessionData = props?.model?.numberOfSearchForms ? true : false;
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);
  const setIsWeekendButtonSelected = useSearchFormStore(
    (state: any) => state.setMeetingsSearchformWeekendButtonSelected
  );
  const isWeekendButtonSelected = useSearchFormStore((state: any) => state.isMeetingsSearchformWeekendButtonSelected);
  /**
   * shop store to access the data
   */
  const shopStoreInitialization = useSearchFormStore((state: any) => state.shopStoreInitialization);
  const isTabbedSearchForm =
    typeof model?.['appliedCssClassNames'] === 'string' && model?.['appliedCssClassNames'].toLowerCase() === 'phoenix'
      ? true
      : false;
  if (model) {
    model['isTabbedSearchForm'] = isTabbedSearchForm;
  }

  const isDesktopView = useCheckBreakpoint('viewportL');
  const isTabletView = useCheckBreakpoint('viewportM');
  const { tags, size } = Types;

  useEffect(() => {
    async function getSession() {
      // Extract the sessionID cookie value from browser cookies
      const sessionID = document?.cookie?.split('sessionID=')[1]?.split(';')[0];
      // Make the client side GET session call
      const response: any = await getSessionData(sessionID, false, false);
      // Initialize the zustand store with the new sessionData
      shopStoreInitialization(response?.sessionData, model);
      // Update the sessionData value in global-store
      updateGlobalData('sessionData', response?.sessionData);
    }
    // make the client side session call from SearchForm component
    if (callGetSessionData) {
      getSession();
    } else {
      // Initialize the zustand store with the sessionData available in props
      !isTabbedSearchForm && shopStoreInitialization(globalData?.sessionData, model);
    }
  }, []);

  const errorMessages = useSearchFormStore((state: any) => state?.errorMessage);
  const meetingsErrorMessage = useSearchFormStore((state: any) => state?.meetingsErrorMessage);
  const errorMessageKey = errorMessages?.errorMessages?.submitSearchFormErrorMessages?.errorMessageKeys[0];
  const meetingsErrorMessageKey = meetingsErrorMessage?.meetingsErrorMessages?.alertCode;
  let alertMessage: any = {};
  if (meetingsErrorMessageKey) {
    //to handle all client side error msgs on meeting and events searchform
    alertMessage.alertMessage = meetingsErrorMessage?.meetingsErrorMessages?.alertMessage;
    alertMessage.alertType = meetingsErrorMessage?.meetingsErrorMessages?.alertType;
  } else if (errorMessageKey === ADV_SEARCH_FORM_TEN_ROOMS_COUNT_ERROR_CODE) {
    /**
     * Display the error Message if the rooms count is b/w 10-25
     */
    alertMessage = returnApplicableAlert(ADV_SEARCH_FORM_TEN_TO_TWENTY_FIVE_ROOMS_COUNT_ERROR_CODE, alertsListArray);
  } else if (errorMessageKey === ADV_SEARCH_FORM_CURR_DATE_ROOM_COUNT_ERROR_CODE) {
    alertMessage = returnApplicableAlert(ADV_SEARCH_FORM_MORE_THAN_TEN_ROOMS_COUNT_ERROR_CODE, alertsListArray);
  }
  return (
    <StyledWrapperDiv
      data-component-name="o-shop-meetingssearchform"
      data-testid="meetingssearchform"
      className={clsx('pb-4', 'pb-md-5', 'standard')}
    >
      <StaticDataContextProvider value={model}>
        {model?.meetingsComponentTitle && (
          <h1 className={clsx('t-subtitle-xl', 'tabs-sub-heading', 'mt-4')}>{model?.meetingsComponentTitle}</h1>
        )}

        <div
          className={clsx(
            'subheading',
            alertMessage && alertMessage?.alertMessage ? '' : 'mb-4',
            model?.meetingsComponentTitle ? '' : 'mt-4'
          )}
        >
          <Text
            copyText={model?.meetingsSubHeading || 'Book a space or add properties to your Request for Proposal (RFP).'}
            element={tags.div}
            fontSize={size.small}
          ></Text>
        </div>
        <div
          className={clsx(alertMessage && alertMessage?.alertMessage && (isTabletView ? 'pt-4 pr-2' : 'pt-4'), 'mb-4')}
        >
          {alertMessage && alertMessage?.alertMessage && (
            <Messages
              className={clsx(isTabletView ? '' : 'px-2', 'meetings-form-error-messages')}
              messageType={alertMessage?.alertType}
              messageHeading={alertMessage?.alertHeading}
              messageText={alertMessage?.alertMessage}
            />
          )}
        </div>
        <div className={clsx('d-flex flex-column flex-md-row')}>
          <div className={clsx('component-wrapper mb-3 mb-md-0 mr-sm-0 mr-md-2 mr-lg-2')}>
            <SearchField
              isModalOpen={false}
              isDesktopView={isDesktopView}
              hideClearCta={false}
              mobileModalHeading={
                model?.isTabbedSearchForm ? model?.destinationModalHeader : model?.expandedPlaceHolderTextMobile
              }
              mobilePlaceHolderText={model?.placeholderTextMobile}
              isMeetingDestField={true}
            />
          </div>
          <div className={clsx('component-wrapper', 'mb-3', 'col-md-6 col-lg-6 col-12', 'pl-0 pr-0 pl-md-2')}>
            <DatePicker
              alignRight={isDesktopView}
              hideTabHeader={true}
              setDefaultDate={true}
              setIsWeekendButtonSelected={setIsWeekendButtonSelected}
              isWeekendButtonSelected={isWeekendButtonSelected}
            />
          </div>
        </div>
        <HotelsAndEvents model={model}></HotelsAndEvents>
      </StaticDataContextProvider>
    </StyledWrapperDiv>
  );
};

export const MeetingsAndEventsSearchForm: FC<MeetingsAndEventsSearchProps> = props => {
  const { model } = props;
  return (
    <StyledMeetingsAndEventsForm tabIndex={0} role="form">
      <MeetingsSearchForm model={model}></MeetingsSearchForm>
    </StyledMeetingsAndEventsForm>
  );
};

export const MeetingsAndEventsSearchConfig = {
  emptyLabel: 'MeetingsAndEventsSearchForm',
  isEmpty: false,
  resourceType: 'mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
};

export const MeetingsAndEventsSearchFormEditable = (props: any) => (
  <EditableComponent config={MeetingsAndEventsSearchConfig} {...props}>
    <MeetingsAndEventsSearchForm {...props} />
  </EditableComponent>
);
