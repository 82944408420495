// Styles for Navigation go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

//Adding some styles here as they are removed from shared/mi-ui-library
//TODO: we can move the alphaColor to common
const alphaColor = {
  gray1: 'rgba(28, 28, 28, 0.56)',
  gray2: 'rgba(28, 28, 28, 0.83)',
  gray3: 'rgba(28, 28, 28, 0.65)',
};

export const StyledNavigation = styled.div`
  z-index: 1;
  .nav-utility-container {
    margin: auto 0 ${toRem(24)} 0;
    padding: ${toRem(12)} 0 ${toRem(24)} ${toRem(20)};
    background-color: inherit;

    ul {
      gap: ${toRem(8)};

      .utility-icon::before {
        height: ${toRem(24)};
        width: ${toRem(24)};
        padding: ${toRem(4)};
      }
    }
  }

  .sub-nav-utility-container {
    margin: auto 0 ${toRem(24)} 0;
    padding: ${toRem(12)} 0 ${toRem(24)} ${toRem(20)};

    ul {
      gap: ${toRem(8)};

      .utility-icon::before {
        height: ${toRem(24)};
        width: ${toRem(24)};
        padding: ${toRem(4)};
      }
    }
  }

  .main-nav {
    list-style: none;
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: ${toRem(52)};

      @media ${baseVariables.mediaQuery.sm} {
        height: ${toRem(88)};
      }

      @media ${baseVariables.mediaQuery.lg} {
        display: block;
      }
    }
    &__open {
      display: block;
    }
    &__nav-items {
      padding: ${toRem(24)};
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(20)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: ${toRem(32)} 0;
        border-top: none;
      }
    }

    &__nav-item {
      padding: ${toRem(14)} 0 ${toRem(10)};
      border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral20']};
      line-height: ${toRem(14)};

      a.m-nav-link {
        font-weight: 400;
        font-size: ${toRem(16)};
        @media ${baseVariables.mediaQuery.desktop} {
          padding: ${toRem(4)} 0;
          margin: 0;
          font-size: ${toRem(14)};
          &:focus {
            border-bottom: unset;
          }
          &:hover,
          &.active {
            border-bottom: solid ${toRem(3)};
            padding-bottom: ${toRem(6)};
          }
        }
      }

      .main-nav__nav-link-arrow {
        height: ${toRem(24)};
        ::before {
          font-size: ${toRem(24)};
        }
      }

      @media ${baseVariables.mediaQuery.lg} {
        border-bottom: unset;
        padding: 0;
        margin-right: ${toRem(20)};
      }
    }

    .exlpore-all {
      margin-left: ${toRem(40)};
    }

    &__menu-btn {
      padding: ${toRem(10)} ${toRem(16)};
      position: absolute;
      left: ${toRem(0)};
      top: ${toRem(0)};
      @media ${baseVariables.mediaQuery.sm} {
        padding: ${toRem(28)} ${toRem(24)};
      }
      span {
        height: ${toRem(32)};
        width: ${toRem(32)};
        display: block;
      }
    }

    &__btn {
      border: 0;
      background-color: transparent;
      .standard & {
        .icon-menu::before {
          color: inherit;
        }
      }
      [class*='icon-'] {
        &::before {
          display: block;
          padding: ${toRem(4)};
        }
      }
    }
    &__nav {
      position: absolute;
      top: ${toRem(52)};
      height: calc(100vh - ${toRem(52)});
      height: calc(100dvh - ${toRem(52)}); // iOS Safari address bar is not accounted for in vh
      width: 100vw;
      display: none;
      overflow: auto;
      flex-direction: column;
      @media ${baseVariables.mediaQuery.sm} {
        top: ${toRem(88)};
        width: 100vw;
        height: calc(100vh - ${toRem(88)});
        height: calc(100dvh - ${toRem(88)}); // iOS Safari address bar is not accounted for in vh
      }
      @media ${baseVariables.mediaQuery.lg} {
        position: static;
        display: inline-flex;
        background: none;
        overflow: visible;
        height: 100%;
        width: 100%;
        flex-direction: unset;
      }
      &-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__overlay {
      @media ${baseVariables.mediaQuery.sm} {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: ${alphaColor['gray1']};
        z-index: -1;
      }
      @media ${baseVariables.mediaQuery.lg} {
        display: none;
      }
    }
  }
`;
