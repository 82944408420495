import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledMegaMenu = styled.div`
  position: fixed;
  top: ${toRem(52)};
  left: 0;
  width: 100vw;
  z-index: 5;
  height: calc(100vh - ${toRem(52)});
  height: calc(100dvh - ${toRem(52)}); // iOS Safari address bar is not accounted for in vh
  overflow-y: auto;

  @media ${baseVariables.mediaQuery.sm} {
    top: ${toRem(88)};
    width: 100vw;
    height: calc(100vh - ${toRem(88)});
    height: calc(100dvh - ${toRem(88)}); // iOS Safari address bar is not accounted for in vh
  }
  @media ${baseVariables.mediaQuery.lg} {
    top: ${toRem(88)};
    width: unset;
    height: auto;
    overflow: hidden;
    position: absolute;
    left: ${toRem(31)};
    right: ${toRem(24)};
    border-radius: 0 0 ${toRem(12)} ${toRem(12)};
    box-shadow: 0 ${toRem(4)} ${toRem(10)} 0 rgba(0, 0, 0, 0.1);
  }
  @media ${baseVariables.mediaQuery.xl} {
    left: 0;
    right: 0;
  }
  .megamenu-wrapper {
    position: relative;
  }
  .sub-nav {
    &__content {
      width: 100%;
      height: max-content;
      @media ${baseVariables.mediaQuery.lg} {
        overflow-x: auto;
      }
    }
    &__close-btn {
      position: absolute;
      @media ${baseVariables.mediaQuery.lg} {
        right: ${toRem(16)};
        bottom: ${toRem(16)};
        gap: ${toRem(6)};
      }
      background: none;
      border: none;
      cursor: pointer;
      :focus {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }
    &__nav {
      display: flex;
      flex-direction: column;
      height: calc(100vh - ${toRem(108)});
      height: calc(100dvh - ${toRem(108)}); // iOS Safari address bar is not accounted for in vh
      @media ${baseVariables.mediaQuery.sm} {
        height: calc(100vh - ${toRem(144)});
        height: calc(100dvh - ${toRem(144)}); // iOS Safari address bar is not accounted for in vh
      }
      @media ${baseVariables.mediaQuery.lg} {
        height: auto;
      }
      &__container {
        padding: ${toRem(24)} ${toRem(16)};
        @media ${baseVariables.mediaQuery.sm} {
          padding: ${toRem(40)} ${toRem(32)};
        }
      }
      .sub-navigation-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          line-height: ${toRem(16)};
        }
      }
    }
    &__back-btn-wrapper {
      z-index: 1;
      position: sticky;
      top: 0;
      height: ${toRem(56)};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid ${baseVariables.color['neutral20']};
      background-color: inherit;

      button {
        position: absolute;
        left: ${toRem(16)};
        background-color: inherit;
        height: ${toRem(24)};
        width: ${toRem(24)};
        padding: 0;
      }
    }
  }
`;
