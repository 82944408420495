export const ALLOWED_FILE_TYPES = ['.xlsx', '.xls'];
export const ROOMINGLIST_SPREADSHEET_MAX_SIZE = 1 * 1024 * 1024; // 1MB
export const EVENT_LIST_SIZE = 20;
export const DEFAULT_TEMPLATE_NAME = 'MIRoomingList.xlsx';
export const ROOMING_PLANNER_SPREADSHEET_URL = '/v1/roominglist/associate/planner-spreadsheet?plannerUploadID=';
export const DEFAULT_PLANNER_SPREADSHEET_NAME = 'RoominglistSpreadsheet.xlsx';
export const COUNTRIES_WITH_STATE_ZIPCODE_REQUIRED = ['US', 'CA'];
export const COUNTRIES_WITH_ZIPCODE_VALIDATION = ['US'];
export const RESLINK_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];
export const RESLINK_IMAGE_MAX_SIZE = 1 * 1024 * 1024; // 1MB
export const RESLINK_IMAGE_TRAY_LENGTH = 3;
export const MINI_HOTEL_CODE_INVALID_ERROR = 'Mini Hotel Code is invalid.';
export const SUBJECT_LINE_MAX_LENGTH = 100;
export const MESSAGE_MAX_LENGTH = 1500;
export const RESLINK_EMAIL_CF_PATH = '/mi/cf-assets/mi-groups/{region}/{locale}/reslink-email.json';
export const SPANISH = 'espanol';
export const SPANISH_DOMAIN = 'Espanol.marriott.com';
export const ARABIC = 'arabic';
export const ARABIC_DOMAIN = 'Arabic.marriott.com';
export const DOMAIN_LOCALE_MAP: Record<string, string> = {
  'Marriott.com': '',
  'Espanol.marriott.com': '/es',
  'Marriott.de': '/de',
  'Marriott.fr': '/fr',
  'Marriott.co.jp': '/ja',
  'Marriott.co.uk': '/en-gb',
  'Marriott.pt': '/pt-br',
  'Marriott.com.ru': '/ru',
  'Marriott.it': '/it',
  'Marriott.co.kr': '/ko',
  'Marriott.com.cn': '.cn',
  'Marriott.vi': '/vi',
  'Marriott.th': '/th',
  'Arabic.marriott.com': '/ar',
};
