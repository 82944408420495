/* eslint-disable @nx/enforce-module-boundaries */
import { FC, useEffect, useRef, useState, useReducer, ReactElement, useContext } from 'react';
import { ComponentProps, PageProps } from './index.types';
import SearchDesktop from './component/organisms/SearchDesktop/SearchDesktop';
import SearchMobile from './component/organisms/SearchMobile/SearchMobile';
import CommonUtils from './utils/CommonUtils';
import { ViewportContext, ContentContext, FormValuesContext, FocusContext } from './index.contexts';
import { StyledSearchContainer } from './index.styles';
import { storeReducer, getInitialStoreValues } from './store/store';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { baseVariables } from '@marriott/mi-ui-library';
import clsx from 'clsx';

import {
  DATE_FORMAT_VALUE,
  SEARCH_SCROLL_TOP_POSITION,
  DEFAULT_API_ACCEPT_LANGUAGE,
} from './constants/ApplicationConstants';
import { resetForm, setDates, setDestination, setDestinationHiddenFields } from './store/store.actions';
import { v4 as uuidv4 } from 'uuid';

import ScrollUtils from './utils/ScrollUtils';
import { getDateObject, getFormattedFromDate, getFormattedSearchDate, getFormattedToDate } from './utils/DateUtils';
import {
  DESTINATION,
  HOME_PAGE_HEADER_HEIGHT,
  MOBILE_SCROLL_HEIGHT,
  DESKTOP_SCROLL_HEIGHT,
} from './constants/StoreConstants';
import { phoenixOfferSuggestedPlacesDetailsQuery } from '@marriott/mi-offers-graphql';
import { convertMetersToMiles } from '../../utils/CommonUtils';
import { useLazyQuery } from '@apollo/client';
import { UXL_ERROR_POLICY } from '../../api/apiConstants';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import AlertNotification from 'libs/mi-ui-library/src/atoms/Alerts';
import { StyledOffersSearchForm } from './OffersSearchForm.styles';
import { processAcceptLang } from '../../utils/OfferUtils';
import { useMounted } from '../../hooks/useMounted';

export const OffersSearchForm: FC<ComponentProps> = props => {
  const { localDateFormat } = useContext(ContentContext);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const { acceptLanguage, offersApiResponse, model } = props;
  const formData = {
    destination: {
      // eslint-disable-next-line prettier/prettier
      displayText:
        offersApiResponse?.responseObject?.edges[0]?.node?.participatingProperties?.properties[0]?.basicInformation
          ?.name,
      propCode: offersApiResponse?.responseObject?.edges[0]?.node?.participatingProperties?.properties[0]?.id,
      clusterCode: offersApiResponse?.responseObject?.edges[0]?.node?.clusterCode,
    },
    dates: {
      lastDefaultDate: offersApiResponse?.responseObject?.edges[0]?.node?.stayEndDate,
      checkin: offersApiResponse?.responseObject?.edges[0]?.node?.stayStartDate,
      checkout: offersApiResponse?.responseObject?.edges[0]?.node?.stayEndDate,
      minimumStay: offersApiResponse?.responseObject?.edges[0]?.node?.minimumStay,
    },
  };
  const [isMobileState, setIsMobile] = useState(CommonUtils.isOnlyMobileView());
  const [formValues, setFormValues] = useReducer(storeReducer, getInitialStoreValues());
  const [UUID, setUUID] = useState(uuidv4());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [focusComp, setFocusComp] = useState('');
  const requestId = props.requestId;
  const acceptLang = processAcceptLang(acceptLanguage ?? DEFAULT_API_ACCEPT_LANGUAGE);
  const staticContent: PageProps = {
    ...props?.model,
    ...{
      requestId: requestId,
      acceptLang: acceptLang,
    },
  };
  const variation = staticContent.variation;
  const previewMode = offersApiResponse?.isPreview;
  staticContent.marshaCode = offersApiResponse?.marsha;
  staticContent.offerId = offersApiResponse?.offerId;

  // fix for issue(CNWEB-5233) to support for different locale by considering AEM value first.
  const dateFormat = (localDateFormat as string) ?? DATE_FORMAT_VALUE;

  // to get last active date on calendar based on last stay date
  const lastDefaultDate = getDateObject(getFormattedFromDate(formData?.dates?.checkout ?? ''), DATE_FORMAT_VALUE);
  const maxCalendarDays = CommonUtils.getNumberOfNights(
    getDateObject(getFormattedFromDate(formData?.dates?.checkin ?? ''), DATE_FORMAT_VALUE),
    lastDefaultDate
  );
  const isMPO = variation === 'multiple-property-offer';
  const isSPO = variation === 'single-property-offer';
  const [isInvalidSessionDate, setIsInvalidSessionDate] = useState(false);
  const [OfferSearchDates, setOfferSearchDates] = useState({
    startDate: '',
    endDate: '',
  });

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);
  const isMounted = useMounted();
  useEffect(() => {
    const offerSearchDates = JSON.parse(window.sessionStorage.getItem('OfferSearchDates') ?? '{}');
    const dateDifference = moment(OfferSearchDates?.startDate).diff(moment(OfferSearchDates?.endDate), 'days');
    offerSearchDates?.startDate &&
      offerSearchDates?.endDate &&
      setOfferSearchDates({
        startDate: offerSearchDates?.startDate,
        endDate:
          dateDifference < formData.dates?.minimumStay
            ? moment(OfferSearchDates?.startDate).add(formData.dates?.minimumStay ?? 1, 'days')
            : offerSearchDates?.endDate,
      });
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processPlaceDetails = (placesDetails: any) => {
    setFormValues(
      setDestinationHiddenFields({
        ...placesDetails?.suggestedPlaceDetails?.location,
        radius: convertMetersToMiles(placesDetails?.suggestedPlaceDetails?.distance),
      })
    );
  };

  const [getSuggestedPlaceDetails, { data: _placesDetails, error: _placesError }] = useLazyQuery(
    phoenixOfferSuggestedPlacesDetailsQuery,
    {
      fetchPolicy: 'cache-first',
      context: {
        headers: {
          'x-request-id': props.requestId,
          'accept-language': acceptLang,
        },
      },
      errorPolicy: UXL_ERROR_POLICY,
      onCompleted: processPlaceDetails,
    }
  );
  /**
   * Effects
   */
  useEffect(() => {
    eventUtil.on('mpoInvalidSessionDateErrFlag', flag => {
      variation === 'multiple-property-offer' && setIsInvalidSessionDate(flag);
    });
    eventUtil.remove('mpoInvalidSessionDateErrFlag', () => {
      ('');
    });
  });
  useEffect(() => {
    if (previewMode === 'true') {
      setFormValues(
        setDates({
          lastDefaultDate: getFormattedFromDate(new Date().toString()),
          fromDate: getDateObject(getFormattedFromDate(new Date().toString()), dateFormat),
          toDate: getDateObject(getFormattedToDate(getFormattedFromDate(new Date().toString()) ?? '', 1), dateFormat),
          // flexible: isFlexible ? 'true' : undefined,
          checkin: getFormattedFromDate(new Date().toString()),
          checkout: getFormattedToDate(getFormattedFromDate(new Date().toString()) ?? '', 1),
          isSelectedDate: true,
        })
      );
      setFormValues(
        setDestination({
          displayText: isMPO ? '' : formData?.destination?.displayText ?? '',
          clusterCode: formData?.destination?.clusterCode ?? '',
          propCode: formData?.destination?.propCode ?? '',
        })
      );
    }

    if (formData && !(previewMode === 'true')) {
      const fromDateExpeired = new Date(formData.dates?.checkin) < new Date();
      const isInvalidSessionEndDate =
        moment(OfferSearchDates?.endDate) <
          moment(OfferSearchDates?.startDate).add(formData.dates?.minimumStay ?? 1, 'days') ||
        moment(OfferSearchDates?.endDate) > moment(formData.dates?.checkout);
      variation === 'multiple-property-offer'
        ? eventUtil.dispatch('mpoInvalidSessionDateErrFlag', isInvalidSessionEndDate)
        : eventUtil.dispatch('invalidSessionDateErrFlag', isInvalidSessionEndDate);
      setFormValues(
        setDates({
          lastDefaultDate: getFormattedFromDate(formData.dates?.checkout ?? ''),
          fromDate: OfferSearchDates?.startDate
            ? moment(new Date(OfferSearchDates?.startDate))
            : fromDateExpeired
            ? moment(new Date())
            : moment(formData.dates?.checkin),
          toDate: OfferSearchDates?.endDate
            ? moment(new Date(OfferSearchDates?.endDate))
            : fromDateExpeired
            ? moment(new Date()).add(formData.dates?.minimumStay ?? 1, 'days')
            : // : moment(new Date(formData.dates?.checkin)).add(formData.dates?.minimumStay ?? 1, 'days'),
              moment(moment(formData.dates?.checkin, 'YYYY-MM-DD').toDate()).add(
                formData.dates?.minimumStay ?? 1,
                'days'
              ),
          // flexible: isFlexible ? 'true' : undefined,
          checkin: getFormattedFromDate(formData.dates?.checkin ?? ''),
          checkout: getFormattedSearchDate(formData.dates?.checkout ?? ''),
          isSelectedDate: true,
          minimumStay: formData.dates?.minimumStay ?? 1,
        })
      );

      setFormValues(
        setDestination({
          displayText: isMPO ? '' : formData?.destination?.displayText ?? '',
          clusterCode: formData?.destination?.clusterCode,
          propCode: formData?.destination?.propCode,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OfferSearchDates?.startDate, OfferSearchDates?.endDate]);

  // to handle resize event
  useEffect(() => {
    /**
     * Handler for resize event
     */
    const resizeHandler = (): void => {
      const currentViewIsMobile = CommonUtils.isOnlyMobileView();
      if (!isMobileState) {
        // viewport was not mobile earlier but now its mobile
        if (currentViewIsMobile) {
          setIsMobile(true);
          setFormValues(resetForm());
        }
      } else {
        // viewport was mobile earlier but now its not
        if (!currentViewIsMobile) {
          setIsMobile(false);
          setFormValues(resetForm());
        }
      }
    };
    window.addEventListener('resize', resizeHandler, { passive: true });
    return (): void => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isMobileState]);

  // when user interacts with any field on desktop, form will scroll up so that popup can be easily visible
  useEffect(() => {
    const container = searchContainerRef.current;
    if (isPopupOpen && !isMobileState && container) {
      const topPosition = container.getBoundingClientRect().top;
      if (topPosition && topPosition > SEARCH_SCROLL_TOP_POSITION) {
        ScrollUtils.smoothScroll(0, topPosition - SEARCH_SCROLL_TOP_POSITION);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  // if change in destination pid, fetch destination hidden values
  useEffect(() => {
    const pid = formValues?.[DESTINATION]?.pid;
    if (pid) {
      getSuggestedPlaceDetails({
        variables: {
          placeId: pid,
        },
      });
    } else {
      if (pid === '') {
        setFormValues(setDestinationHiddenFields({}));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.[DESTINATION]?.pid]);
  const getWrapperClassName = () => {
    return clsx({
      [`${variation} offers-spo enable-overlap priviewMode`]: previewMode === 'true' && isSPO,
      [`${variation} offers-search-form-fragment search-form-fragment offers-mpo priviewMode`]:
        previewMode === 'true' && !isSPO,
      [`${variation} offers-spo enable-overlap search-form-wrapper document_search_form_container`]:
        previewMode !== 'true' && isSPO,
      [`${variation} offers-search-form-fragment search-form-fragment offers-mpo`]: previewMode !== 'true' && !isSPO,
    });
  };

  // const [aemFragmentHeaderHeight, setAemFragmentHeaderHeight] = useState<number>(80);

  //useEffect(() => {
  /**
   * to set the homepage Header height
   */
  //const homePageHeader = document.querySelector('.m-header__container');
  // const homePageBannerComp = document.querySelector('.ab');
  // const homePageBannerCompHeight = homePageBannerComp ? ALERT_BANNER_H : 0;
  // const homePageHeaderHeight = homePageHeader?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_CONST_HEIGHT;
  // setAemFragmentHeaderHeight(homePageHeaderHeight);
  //}, []);

  const scrollThreshold = isMobileState ? MOBILE_SCROLL_HEIGHT : DESKTOP_SCROLL_HEIGHT;
  const scrollPosition = useRef<number>(0);
  let lastScrollPosition = 0;
  let searchFormEl = null;
  let searchFormEl2 = null;

  /**
   * checks the scroll position wrt threshold(header) so as to add the necessary scroll CSS
   */
  const handleScrollPosition = () => {
    searchFormEl = document.querySelector('.document_search_form_container_el_ref');
    searchFormEl2 = document.querySelector('.document_search_form_container');
    if (scrollPosition.current < scrollThreshold) {
      searchFormEl2?.classList.add('search-form-wrapper');
    }
    if (window.pageYOffset > scrollThreshold && scrollPosition.current < scrollThreshold) {
      scrollPosition.current = window.pageYOffset;
      /**
       *makes the SearchForm full width if user scrolls and vice versa if back to original state
       */

      if (searchFormEl) {
        searchFormEl?.classList.add('color-scheme1');
      }
      if (searchFormEl2) {
        searchFormEl2?.classList.add('search-form-wrapper');
        searchFormEl2?.classList.add('color-scheme1');
      }
    } else if (window.pageYOffset < scrollThreshold && scrollPosition.current > scrollThreshold) {
      scrollPosition.current = window.pageYOffset;
      if (searchFormEl) {
        searchFormEl?.classList.remove('color-scheme1');
      }
      if (searchFormEl2) {
        searchFormEl2?.classList.add('search-form-wrapper');
      }
    }
  };

  /**
   * function to handle the scroll state of the searchForm when user scrolled(fsd-80422)
   */
  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    searchFormEl = document.querySelector('.document_search_form_container_el_ref');
    searchFormEl2 = document.querySelector('.document_search_form_container');
    /**
     * checks the scroll previous position and current position
     */
    if (currentScrollPosition !== 0 && currentScrollPosition < scrollThreshold) {
      searchFormEl?.classList.add('search-container-top');
    } else if (currentScrollPosition > scrollThreshold && currentScrollPosition > lastScrollPosition) {
      /**
       * For scrolling down add the respective CSS
       */
      handleScrollPosition();
      searchFormEl?.classList.add('sticky-search-container');
      searchFormEl2?.classList.remove('search-form-wrapper');
      searchFormEl?.classList.remove('sticky-search-top');
      searchFormEl?.classList.remove('search-container-top');
    } else {
      /**
       * For scrolling top add the respective CSS
       */
      handleScrollPosition();
      searchFormEl?.classList.add('sticky-search-top');
      const searchFormEl3 = document.querySelector('.sticky-search-top');
      const homePageHeaderComp = document.querySelector('.m-header__container');
      const homePageHeaderCompHeight = homePageHeaderComp?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_HEIGHT;
      if (searchFormEl3) {
        // Use type assertion to specify the element type
        const searchFormElWithStyle = searchFormEl3 as HTMLElement;
        /**
         * sets the position top css value dynamically based on the header height if user scrolls top
         */
        searchFormElWithStyle.style.top = `${homePageHeaderCompHeight}px`;
      }
      searchFormEl?.classList.remove('sticky-search-container');
    }
    lastScrollPosition = currentScrollPosition;
    /**
     * remove the sticky css class if searchForm back to top
     */
    if (currentScrollPosition < scrollThreshold) {
      searchFormEl?.classList.remove('sticky-search-top');
      searchFormEl?.classList.remove('sticky-search-container');
      searchFormEl?.classList.remove('search-container-top');
      searchFormEl2?.classList.add('search-form-wrapper');
    }
  };

  const TabbedForm =
    typeof model?.['appliedCssClassNames'] === 'string' && model?.['appliedCssClassNames'].toLowerCase() === 'phoenix'
      ? true
      : false;

  useEffect(() => {
    /**
     * adding the scroll event if handleScroll triggers
     * removes scroll if were back to original state
     */
    if (!TabbedForm && isSPO) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [TabbedForm]);

  const getComponent = (
    isTabletAndAbove: boolean,
    isHideDestination: boolean,
    isPublishUserSelection: boolean,
    isHideFlexibleDates: boolean,
    isMpo: boolean,
    isSPO: boolean,
    previewMode: boolean
  ): ReactElement => {
    // on client side keeping only desktop or mobile view
    // if (typeof window !== 'undefined') {
    if (isMounted) {
      // return isMobileState ? (
      return !isTabletAndAbove ? (
        <SearchMobile
          isHideDestinationProps={isHideDestination}
          isPublishUserSelection={isPublishUserSelection}
          isHideFlexibleDates={isHideFlexibleDates}
          isMPO={isMpo}
          isSPO={isSPO}
          previewMode={previewMode}
          acceptLanguage={acceptLang}
          colorScheme={props?.model?.appliedCssClassNames ?? 'color-scheme1'}
        />
      ) : (
        <SearchDesktop
          isHideDestinationProps={isHideDestination}
          isPublishUserSelection={isPublishUserSelection}
          isHideFlexibleDates={isHideFlexibleDates}
          isMPO={isMpo}
          isSPO={isSPO}
          previewMode={previewMode}
          acceptLanguage={acceptLang}
          colorScheme={props?.model?.appliedCssClassNames ?? 'color-scheme1'}
        />
      );
    }

    // in SSR, device size is unknown hence keeping both mobile and desktop views and hiding them using CSS
    return (
      <>
        <SearchMobile
          className="mobile-only-show"
          isHideDestinationProps={isHideDestination}
          isPublishUserSelection={isPublishUserSelection}
          isHideFlexibleDates={isHideFlexibleDates}
          acceptLanguage={acceptLang}
        />
        <SearchDesktop
          className="mobile-only-hide"
          isHideDestinationProps={isHideDestination}
          isPublishUserSelection={isPublishUserSelection}
          isHideFlexibleDates={isHideFlexibleDates}
          acceptLanguage={acceptLang}
        />
      </>
    );
  };

  return (
    // ContenetContext is to provide authored values from AEM
    <ContentContext.Provider value={staticContent}>
      {/* ViewportContext is to check if it's in mobile view */}
      <ViewportContext.Provider value={isMobileState}>
        {/* FocusContext is to set or check which part of search is on focus */}
        <FocusContext.Provider value={{ focusComp, setFocusComp }}>
          {/* FormValuesContext is to manage any form related values */}
          <FormValuesContext.Provider
            value={{ formValues, setFormValues, UUID, setUUID, setIsPopupOpen, maxCalendarDays }}
          >
            {isMounted && (
              <StyledOffersSearchForm
                data-component-name="o-offers-searchform"
                data-testid="offers-searchform"
                className={clsx('document_search_form_container_el_ref')}
              >
                <StyledSearchContainer
                  className={`search__container ${
                    staticContent?.['hideDestination'] ? `search__container-hide-destination` : ``
                  }${isSPO && ' offers-spo'}`}
                  ref={searchContainerRef}
                >
                  <div className={getWrapperClassName()} id="offers-search-form">
                    <pre style={{ display: 'none' }}>{JSON.stringify({ staticContent })}</pre>
                    <pre style={{ display: 'none' }}>{`==========>>` + staticContent?.['hideDestination']}</pre>
                    <script
                      src="https://maps.googleapis.com/maps/api/js?v=3.exp&client=gme-marriottinternational&libraries=places,geometry&signed_in=false&region="
                      defer
                    ></script>
                    {getComponent(
                      isTabletAndAbove,
                      staticContent?.['hideDestination'] === `true`,
                      staticContent['submitAction'] === `publish-user-selection`,
                      staticContent?.['hideFlexibleDatesCheckbox'] === `true`,
                      isMPO,
                      isSPO,
                      staticContent?.['previewMode'] === 'true'
                    )}
                  </div>
                  {isInvalidSessionDate && (
                    <AlertNotification errorMessage={model?.offersRateNotAvailable} className={'error-message alert'} />
                  )}
                </StyledSearchContainer>
              </StyledOffersSearchForm>
            )}
          </FormValuesContext.Provider>
        </FocusContext.Provider>
      </ViewportContext.Provider>
    </ContentContext.Provider>
  );
};
