import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledVariableColumn = styled.div`
  .column-containers .aem-container:first-child {
    padding: 0px;
  }
  ul {
    padding: 0;
    list-style: unset;
  }
  .reverse-stack {
    flex-direction: row-reverse;
    @media ${baseVariables.mediaQuery.md} {
      flex-direction: row;
    }
  }
  .reverse-stack-column {
    flex-direction: column-reverse;
    @media ${baseVariables.mediaQuery.md} {
      flex-direction: row;
    }
  }
  .variable_column_header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: ${toRem(32)};
    margin-bottom: ${toRem(24)};
    a,
    button {
      width: fit-content;
    }
    .m-link-tertiary-button-external,
    .m-link-tertiary-button-external::after,
    .m-link-action {
      font-size: inherit;
      line-height: inherit;
    }
  }
  margin-bottom: ${toRem(32)};
`;
