/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { BrandFilter } from '@marriott/mi-shop-components';
import { OffersSearchResultsFilterProps } from './../OffersSearchResults.types';
import clsx from 'clsx';
import { Button, CheckboxControlsList, Modal } from '@marriott/mi-ui-library';
import { useEffect, useState } from 'react';
import { INTERNAL_CTA_TYPE } from '../../../constants/CommonConstants';
import { StyledOfferSearchAllFilters } from './../OffersSearchResults.styles';
import {
  BrandProps,
  availableBrandsType,
  brandCategoriesDataType,
  brandCategoryTitleMappingType,
  brandFilterProps,
  CheckboxControlsListProps,
  ControlsProps,
} from './OffersSearchResultsFilters.types';
import { getBrandCategorySelectAllLabel, getControlsData } from '../../../utils/OfferUtils';

export const OffersSearchResultsFilter: React.FC<OffersSearchResultsFilterProps> = props => {
  const ariesOffer = JSON.parse(window.sessionStorage.getItem('OffersfilterData') ?? '{}');
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>([]);
  const [selectedOfferCategory, setSelectedOfferCategory] = useState<ControlsProps[]>([]);
  const [selectedOfferType, setSelectedOfferType] = useState<ControlsProps[]>([]);
  const [selectedFilterState, setSelectedFilterState] = useState<ControlsProps[]>([]);
  const [selectedFilterCity, setSelectedFilterCity] = useState<ControlsProps[]>([]);
  const [selectedFilterCountry, setSelectedFilterCountry] = useState<ControlsProps[]>([]);
  const [isCategoryFilterModal, setIsCategoryFilterModal] = useState<boolean>(false);
  const [updateFilterLabel, setUpdateFilterLabel] = useState<boolean>(true);
  const isFilterSelected = props?.brandFacets.concat(
    props?.offerCategory,
    props?.offerType,
    props?.filterState,
    props?.filterCity,
    props?.filterCountry
  );
  const model = props.model;
  const brandCategoriesData: brandCategoriesDataType = [];

  useEffect(() => {
    setSelectedBrands(props?.selectedBrands);
    setSelectedOfferCategory(props?.selectedOfferCategory);
    setSelectedOfferType(props?.selectedOfferType);
    setSelectedFilterState(props?.selectedFilterState);
    setSelectedFilterCity(props?.selectedFilterCity);
    setSelectedFilterCountry(props?.selectedFilterCountry);
  }, [
    props?.selectedBrands?.length,
    props?.selectedOfferCategory?.length,
    props?.selectedOfferType?.length,
    props?.selectedFilterState?.length,
    props?.selectedFilterCity?.length,
    props?.selectedFilterCountry?.length,
  ]);

  const brandCategoryTitleMapping: brandCategoryTitleMappingType = {
    luxuryBrands: model?.offersBrandFilterLuxury,
    premiumBrands: model?.offersBrandFilterPremium,
    selectBrands: model?.offersBrandFilterSelect,
    longerStayBrands: model?.offersBrandFilterLongerStay,
  };

  const getBrandCategoriesData = (brandCategory: string) => {
    let brandsList = [];
    brandsList =
      model?.brands &&
      model?.brands[brandCategory]?.map((brand: { code: string; title: string }) => {
        const isDisabled = props?.availableBrands?.some((item: availableBrandsType) => {
          return item?.code === brand?.code;
        });
        return {
          code: brand?.code,
          brandTagTitle: brand?.title,
          description: '',
          brandTagId: brand?.code,
          isDisabled: !isDisabled,
        };
      });
    const key = brandCategory as keyof typeof brandCategoryTitleMapping; // to allow indexing on object
    const category = {
      categoryTitle: `${brandCategoryTitleMapping[key]} ${model?.offersBrandFilter}`,
      categorySelectLabel: model?.[getBrandCategorySelectAllLabel(key)],
      categoryClearLabel: `${model?.offersFilterClear} ${brandCategoryTitleMapping[key]} ${model?.offersBrandFilter}`,
      brands: brandsList,
    };
    brandCategoriesData.push(category);
  };
  getBrandCategoriesData('luxuryBrands');
  getBrandCategoriesData('premiumBrands');
  getBrandCategoriesData('selectBrands');
  getBrandCategoriesData('longerStayBrands');

  const handleBrandFilter = (selectedBrandAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedBrands(selectedBrandAr);
    props.handleDynamicBrands(selectedBrandAr);
  };
  const handleOfferTypeChange = (selectedofferTypeAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedOfferType(selectedofferTypeAr);
    props.handleDynamicOfferType(selectedofferTypeAr);
  };

  const handleOfferCategoryChange = (selectedOfferCategoryAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedOfferCategory(selectedOfferCategoryAr);
    props.handleDynamicOfferCategory(selectedOfferCategoryAr);
  };

  const handleStateFilterChange = (selectedStateFilterAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedFilterState(selectedStateFilterAr);
    props.handleDynamicState(selectedStateFilterAr);
  };

  const handleCityFilterChange = (selectedCityFilterAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedFilterCity(selectedCityFilterAr);
    props.handleDynamicCity(selectedCityFilterAr);
  };

  const handleCountryFilterChange = (selectedCountryFilterAr: any) => {
    setUpdateFilterLabel(false);
    setSelectedFilterCountry(selectedCountryFilterAr);
    props.handleDynamicCountry(selectedCountryFilterAr);
  };

  const handleClearBtn = () => {
    setUpdateFilterLabel(true);
    setSelectedBrands([]);
    setSelectedOfferCategory([]);
    setSelectedOfferType([]);
    setSelectedFilterState([]);
    setSelectedFilterCity([]);
    setSelectedFilterCountry([]);
    props.handleClearAllFilters();
    props.handleDynamicBrands([]);
    props.handleDynamicOfferType([]);
    props.handleDynamicOfferCategory([]);
    props.handleDynamicState([]);
    props.handleDynamicCity([]);
    props.handleDynamicCountry([]);
  };

  //props received by BrandFilter
  const BrandFilterProps: brandFilterProps = {
    title: `${model?.offersBrandFilter}`,
    brandCategories: brandCategoriesData,
    showSeparator: true,
    // defaultSelected includes all those brands which are selected and highlited for filtering the properties.
    defaultSelected: selectedBrands,
    onChange: handleBrandFilter,
    isHotelBrandSelectedFlag: true,
  };

  //props for CheckboxControlsList
  const OfferTypeProps: CheckboxControlsListProps = {
    title: `${model?.offersFiltersOffertypes}`,
    controls: getControlsData(props?.availableOfferType),
    enableShowMoreToggle: true,
    defaultSelected: selectedOfferType,
    onChange: handleOfferTypeChange, //array of changed value
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'offerType',
    showSeparator: true,
  };

  //props for CheckboxControlsList
  const OfferCategoryProps: CheckboxControlsListProps = {
    title: `${model?.offersFiltersOffercategories}`,
    controls: getControlsData(props?.availableOfferCategory),
    enableShowMoreToggle: true,
    defaultSelected: selectedOfferCategory,
    onChange: handleOfferCategoryChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'offerCategory',
    showSeparator: true,
  };

  //props for CheckboxControlsList
  const StateFilterProps: CheckboxControlsListProps = {
    title: `${model?.offersFiltersState}`,
    controls: getControlsData(props?.availableFilterState),
    enableShowMoreToggle: true,
    defaultSelected: selectedFilterState,
    onChange: handleStateFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'filterState',
    showSeparator: true,
  };

  //props for CheckboxControlsList
  const CityFilterProps: CheckboxControlsListProps = {
    title: `${model?.offersFiltersCity}`,
    controls: getControlsData(props?.availableFilterCity),
    enableShowMoreToggle: true,
    defaultSelected: selectedFilterCity,
    onChange: handleCityFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'filterCity',
    showSeparator: true,
  };

  //props for CheckboxControlsList
  const CountryFilterProps: CheckboxControlsListProps = {
    title: `${model?.offersFiltersCountry}`,
    controls: getControlsData(props?.availableFilterCountry),
    enableShowMoreToggle: true,
    defaultSelected: selectedFilterCountry,
    onChange: handleCountryFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'filterCountry',
    showSeparator: true,
  };

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
    setUpdateFilterLabel(true);
    props.handleDynamicBrands(ariesOffer?.['selectedBrands'] ?? []);
    props.handleDynamicOfferType(ariesOffer?.['selectedOfferType'] ?? []);
    props.handleDynamicOfferCategory(ariesOffer?.['selectedOfferCategory'] ?? []);
    props.handleDynamicState(ariesOffer?.['selectedFilterState'] ?? []);
    props.handleDynamicCity(ariesOffer?.['selectedFilterCity'] ?? []);
    props.handleDynamicCountry(ariesOffer?.['selectedFilterCountry'] ?? []);
    setSelectedBrands(ariesOffer?.['selectedBrands'] ?? []);
    setSelectedOfferCategory(ariesOffer?.['selectedOfferCategory'] ?? []);
    setSelectedOfferType(ariesOffer?.['selectedOfferType'] ?? []);
    setSelectedFilterState(ariesOffer?.['selectedFilterState'] ?? []);
    setSelectedFilterCity(ariesOffer?.['selectedFilterCity'] ?? []);
    setSelectedFilterCountry(ariesOffer?.['selectedFilterCountry'] ?? []);
  };

  return (
    <StyledOfferSearchAllFilters className="offer-filters">
      <div className="filter-btns-wrapper">
        <Button
          callback={toggleModal}
          className={clsx(
            `button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button ${
              isFilterSelected?.length && 'active'
            }`
          )}
          custom_click_track_value={`${model?.id}|${model?.offersBrandFilter}-${model?.offersFilters}|${INTERNAL_CTA_TYPE}`}
          trackingProperties={model?.trackingProperties}
        >
          <span
            className={`icon-s ${
              isFilterSelected.length && updateFilterLabel ? ' all-filter-badge' : 'icon-dining-filter'
            }`}
          >
            {' '}
            {`${isFilterSelected?.length && updateFilterLabel ? isFilterSelected.length : ''}`}
          </span>
          {model?.offersAllFilters}
        </Button>
      </div>
      {popupOpenState && (
        <Modal
          className={`custom-scrollbar modal-main-container ${popupOpenState && 'close-popup-header'} ${
            isCategoryFilterModal && 'category-filter-modal'
          }`}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          disableScrollOnBody={true}
          allFilterPopUp={true}
          onClose={(): void => {
            setUpdateFilterLabel(true);
            setPopupOpenState(false);
          }}
        >
          <Modal.Header
            className="modal-header t-subtitle-xl"
            labelText={isCategoryFilterModal ? model?.offersBrandFilter : model?.offersFilters}
            popupHeaderOnCLoseFunc={(): void => {
              setIsCategoryFilterModal(false);
              setPopupOpenState(false);
              setUpdateFilterLabel(true);
            }}
            closeIconClickTrackValue={`${model?.id}| CloseModal |${INTERNAL_CTA_TYPE}`}
          />
          <Modal.Body className="all-filters custom-scrollbar">
            <div>
              <BrandFilter {...BrandFilterProps} />
              {OfferTypeProps?.controls?.length > 0 ? <CheckboxControlsList {...OfferTypeProps} /> : null}
              {OfferCategoryProps?.controls?.length > 0 ? <CheckboxControlsList {...OfferCategoryProps} /> : null}
              {CountryFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...CountryFilterProps} /> : null}
              {StateFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...StateFilterProps} /> : null}
              {CityFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...CityFilterProps} /> : null}
            </div>
          </Modal.Body>
          <Modal.Footer
            clearClickHandler={handleClearBtn}
            setPopUpOpenState={setPopupOpenState}
            applyBtnClickHandler={() => {
              setUpdateFilterLabel(true);
              props.handleApplyFilter(
                selectedBrands,
                selectedOfferCategory,
                selectedOfferType,
                selectedFilterState,
                selectedFilterCity,
                selectedFilterCountry
              );
            }}
            clearButtonLabel={model?.offersClearFilters}
            applyLabel={model?.offersFilterApply}
            className="modal-footer"
            clickTrackingLoc={'SEARCH_FILTER_MODAL'}
            applyBtnClickTrackValue={`${model?.id}|${model?.offersFilterApply}|${INTERNAL_CTA_TYPE}`}
            clearBtnClickTrackValue={`${model?.id}|${model?.offersClearFilters}|${INTERNAL_CTA_TYPE}`}
          />
        </Modal>
      )}
    </StyledOfferSearchAllFilters>
  );
};
